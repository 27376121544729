import { CONSTANTS } from 'helpers/constants';
import { atom } from 'recoil';

const permanentStateAtom = atom({
  key: CONSTANTS.ATOM_KEYS.PERMANENT_STATE,
  default: {
  },
});

export {
  permanentStateAtom,
};
