import { Card } from '@material-ui/core';
import styled from 'styled-components';
import STYLED_MACROS from 'styles/styledMacros';

export const TabCard = styled(Card)`
  background: ${STYLED_MACROS.BACKGROUND.DARK};
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const DarkCard = styled(Card)`
  background: ${STYLED_MACROS.BACKGROUND.DARK};
  padding: ${(props) => props.theme.spacing(2)}px;
  position: relative;
`;

export const DefaultCard = styled(Card)`
  background: ${STYLED_MACROS.BACKGROUND.DEFAULT};
  padding: ${(props) => props.theme.spacing(2)}px;
  position: relative;
`;

export const LightCard = styled(Card)`
  background: ${STYLED_MACROS.BACKGROUND.LIGHT};
  padding: ${(props) => props.theme.spacing(2)}px;
  position: relative;
`;

export const BottomModalCard = styled(Card)`
  background: ${STYLED_MACROS.BACKGROUND.LIGHT};
  padding: ${(props) => props.theme.spacing(3)}px;
  width: 100%;
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px 16px 0 0;
  flex-grow: 1;
`;
