import { useWindowSize } from 'helpers/hooks';
import React, { useContext } from 'react';

const WindowResizeContext = React.createContext(0);

export default WindowResizeContext;

const { Provider } = WindowResizeContext;

const WindowSizeProvider = ({ children }: { children: React.ReactChildren }) => {
  const windowSize = useWindowSize();

  return (
    <Provider value={windowSize}>
      {
        children
      }
    </Provider>
  );
};

export const useWindowResize = () => useContext(WindowResizeContext);

export {
  WindowSizeProvider,
};
