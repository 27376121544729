import { gql } from '@apollo/client';

export const ServiceProductFragment = gql`
  fragment ServiceProductFragment on ServiceProduct {
    id
    accommodationId
    icon
    name
    description
  }
`;

export const ReservationPackageFragment = gql`
  fragment ReservationPackageFragment on ReservationPackage {
    id
    accommodationId
    roomTypeId
    name
    adjustPrice
    description
    packageOptions {
      isSelectable
      serviceProductId
      defaultAmount
      maxAmount
      price
      product {
        ...ServiceProductFragment
      }
    }
  }

  ${ServiceProductFragment}
`;

export const OtaProductFragment = gql`
  fragment OtaProductFragment on OtaProduct {
    id
    accommodationId
    reservationPackageId
    roomTypeId
    isActive
    type
    commissionRate
    data
    parserValues
    parserAcronyms
  }
`;

export const AccommodationOtaFragment = gql`
  fragment AccommodationOtaFragment on AccommodationOta {
    id
    accommodationId
    name
    shortName
    registeredName
    forwardEmail
    key
    otaProducts {
      ...OtaProductFragment
    }
  }

  ${OtaProductFragment}
`;

export const getAccommodationPackagesQuery = gql`
  query getAccommodationPackages($accommodationId: ID!) {
    getAccommodationPackages(accommodationId: $accommodationId) {
      ...ReservationPackageFragment
    }
  }

  ${ReservationPackageFragment}
`;

export const getAccommodationServiceProductsQuery = gql`
  query getAccommodationServiceProducts($accommodationId: ID!) {
    getAccommodationServiceProducts(accommodationId: $accommodationId) {
      ...ServiceProductFragment
    }
  }

  ${ServiceProductFragment}
`;

export const getAccommodationOtaListQuery = gql`
  query getAccommodationOtaList($accommodationId: ID!) {
    getAccommodationOtaList(accommodationId: $accommodationId) {
      ...AccommodationOtaFragment
    }
  }

  ${AccommodationOtaFragment}
`;

export const addAccommodationOtaMutation = gql`
  mutation addAccommodationOtaMutation($input: AddAccommodationOtaInput!) {
    addAccommodationOta(input: $input) {
      ...AccommodationOtaFragment
    }
  }

  ${AccommodationOtaFragment}
`;

export const modifyAccommodationOtaMutation = gql`
  mutation modifyAccommodationOtaMutation($input: ModifyAccommodationOtaInput!) {
    modifyAccommodationOta(input: $input) {
      ...AccommodationOtaFragment
    }
  }

  ${AccommodationOtaFragment}
`;

export const deleteAccommodationOtaMutation = gql`
  mutation deleteAccommodationOtaMutation($id: ID!) {
    deleteAccommodationOta(id: $id)
  }
`;

export const addOtaProductMutation = gql`
  mutation addOtaProductMutation($input: AddOtaProductInput!) {
    addOtaProduct(input: $input) {
      ...OtaProductFragment
    }
  }

  ${OtaProductFragment}
`;

export const modifyOtaProductMutation = gql`
  mutation modifyOtaProductMutation($input: ModifyOtaProductInput!) {
    modifyOtaProduct(input: $input) {
      ...OtaProductFragment
    }
  }

  ${OtaProductFragment}
`;

export const deleteOtaProductMutation = gql`
  mutation deleteOtaProductMutation($id: ID!) {
    deleteOtaProduct(id: $id)
  }
`;

export const addReservationPackageMutation = gql`
  mutation addReservationPackageMutation($input: AddReservationPackageInput!) {
    addReservationPackage(input: $input) {
      ...ReservationPackageFragment
    }
  }

  ${ReservationPackageFragment}
`;

export const modifyReservationPackageMutation = gql`
  mutation modifyReservationPackageMutation($input: ModifyReservationPackageInput!) {
    modifyReservationPackage(input: $input) {
      ...ReservationPackageFragment
    }
  }

  ${ReservationPackageFragment}
`;

export const deleteReservationPackageMutation = gql`
  mutation deleteReservationPackageMutation($id: ID!) {
    deleteReservationPackage(id: $id)
  }
`;

export const addServiceProductMutation = gql`
  mutation addServiceProductMutation($input: AddServiceProductInput!) {
    addServiceProduct(input: $input) {
      ...ServiceProductFragment
    }
  }

  ${ServiceProductFragment}
`;

export const modifyServiceProductMutation = gql`
  mutation modifyServiceProductMutation($input: ModifyServiceProductInput!) {
    modifyServiceProduct(input: $input) {
      ...ServiceProductFragment
    }
  }

  ${ServiceProductFragment}
`;

export const deleteServiceProductMutation = gql`
  mutation deleteServiceProductMutation($id: ID!) {
    deleteServiceProduct(id: $id)
  }
`;

export const CompanyProfileFragment = gql`
  fragment CompanyProfileFragment on CompanyProfile {
    id
    accommodationOtaId
    contractDate
    saleStartDate
    saleEndDate
    contact
    address1
    address2
    email
    memo
  }
`;

export const CompanyAssigneeFragment = gql`
  fragment CompanyAssigneeFragment on CompanyAssignee {
    id
    accommodationOtaId
    name
    task
    department
    position
    phone
    email
    memo
  }
`;

export const OtaPriceContractionFragment = gql`
  fragment OtaPriceContractionFragment on OtaPriceContraction {
    id
    name
    startDate
    endDate
    roomTypeId
    reservationPackageId
    mon
    tue
    wed
    thu
    fri
    sat
    sun
    price
  }
`;

export const FullAccommodationOtaFragment = gql`
  fragment FullAccommodationOtaFragment on AccommodationOta {
    id
    accommodationId
    name
    shortName
    registeredName
    forwardEmail
    key
    otaProducts {
      ...OtaProductFragment
    }
    profile {
      ...CompanyProfileFragment
    }
    assignees {
      ...CompanyAssigneeFragment
    }
  }

  ${CompanyProfileFragment}
  ${CompanyAssigneeFragment}
  ${OtaProductFragment}
`;

export const getSingleAccommodationOta = gql`
  query getSingleAccommodationOta($id: ID!) {
    getSingleAccommodationOta(id: $id) {
      ...FullAccommodationOtaFragment
    }
  }
  ${FullAccommodationOtaFragment}
`;

export const modifyCompanyProfileMutation = gql`
  mutation modifyCompanyProfile($accommodationOtaId: ID!, $input: CompanyProfileInput!) {
    modifyCompanyProfile(accommodationOtaId: $accommodationOtaId, input: $input) {
      ...FullAccommodationOtaFragment
    }
  }

  ${FullAccommodationOtaFragment}
`;

export const addCompanyAssigneeMutation = gql`
  mutation addCompanyAssignee($accommodationOtaId: ID!, $input: AddCompanyAssigneeInput!) {
    addCompanyAssignee(accommodationOtaId: $accommodationOtaId, input: $input) {
      ...CompanyAssigneeFragment
    }
  }
  ${CompanyAssigneeFragment}
`;

export const modifyCompanyAssigneeMutation = gql`
  mutation modifyCompanyAssignee($input: ModifyCompanyAssigneeInput!) {
    modifyCompanyAssignee(input: $input) {
      ...CompanyAssigneeFragment
    }
  }
  ${CompanyAssigneeFragment}
`;

export const deleteCompanyAssigneeMutation = gql`
  mutation deleteCompanyAssignee($id: ID!) {
    deleteCompanyAssignee(id: $id) 
  }
`;

export const getOtaPriceContractionsQuery = gql`
  query getOtaPriceContractions($accommodationOtaId: ID!, $roomTypeId: ID, $reservationPackageId: ID) {
    getOtaPriceContractions(accommodationOtaId: $accommodationOtaId, roomTypeId: $roomTypeId, reservationPackageId: $reservationPackageId) {
      ...OtaPriceContractionFragment
    }
  }

  ${OtaPriceContractionFragment}
`;

export const addOtaPriceContractionMutation = gql`
  mutation addOtaPriceContraction($accommodationOtaId: ID!, $input: AddOtaPriceContractionInput!) {
    addOtaPriceContraction(accommodationOtaId: $accommodationOtaId, input: $input) {
      ...OtaPriceContractionFragment
    }
  }
  ${OtaPriceContractionFragment}
`;

export const modifyOtaPriceContractionMutation = gql`
  mutation modifyOtaPriceContraction($input: ModifyOtaPriceContractionInput!) {
    modifyOtaPriceContraction(input: $input) {
      ...OtaPriceContractionFragment
    }
  }
  ${OtaPriceContractionFragment}
`;

export const deleteOtaPriceContractionMutation = gql`
  mutation deleteOtaPriceContraction($id: ID!) {
    deleteOtaPriceContraction(id: $id)
  }
`;
