import { gql } from '@apollo/client';

export const getConciergeConfigQuery = gql`
  query getConciergeConfig($accommodationId: ID!) {
    getConciergeConfig(accommodationId: $accommodationId) {
      cpid
      dataDogApplicationId
      dataDogClientId
      slackId
    }
  }
`;

export const getConciergePaymentInfoQuery = gql`
  query getConciergePaymentInfo(
    $paymentInfoId: String!
    $sessionToken: String!
    $reservationId: String!
  ) {
    getConciergePaymentInfo(
      paymentInfoId: $paymentInfoId
      sessionToken: $sessionToken
      reservationId: $reservationId
    ) {
      id
      reservationId
      accommodationId
      orderId
      amount
      data
      type
      sessionToken
      status
    }
  }
`;

export const requestCoampayDataMutation = gql`
  mutation requestCoampayData($input: CoampayPurchaseInput!) {
    requestCoampayData(input: $input) {
      infoId
      data
      cpid
    }
  }
`;

export const modifyConciergeReservationMutation = gql`
  mutation modifyConciergeReservation(
    $input: ModifyConciergeReservationInput!
  ) {
    modifyConciergeReservation(input: $input)
  }
`;

export const getConciergeServiceSettingByGuestSessionQuery = gql`
  query getConciergeServiceSettingByGuestSession(
    $input: QueryConciergeServiceSettingInput!
  ) {
    getConciergeServiceSettingByGuestSession(input: $input) {
      enableConcierge
      onSitePaymentByCash
      onSitePaymentByCard
      tempWorkingStop
      roomServiceActivated
      amenityActivated
    }
  }
`;

export const getConciergeWorkSettingsQuery = gql`
  query getConciergeWorkSettings(
    $accommodationId: ID!
    $weekDays: [String]
  ) {
    getConciergeWorkSettings(
      accommodationId: $accommodationId 
      weekDays: $weekDays
    ) {
      weekDay
      workType
      value
    }
  }
`;
