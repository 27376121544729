import { AUDIO_CLOUDFRONT_HOST, CONSTANTS } from 'helpers/constants';

const PARSED_NUMBER = Symbol('PARSED_NUMBER');

export const AUDIO_PRESET = {
  roomAssigned: [PARSED_NUMBER, 'room_assigned'],
  roomCheckedInRent: [PARSED_NUMBER, 'room_checked_in_rent'],
  roomCheckedInLodge: [PARSED_NUMBER, 'room_checked_in_lodge'],
  roomGuestGoOut: [PARSED_NUMBER, 'room_guest_goout'],
  roomGuestLeft: [PARSED_NUMBER, 'room_guest_left'],
  roomCheckOut: [PARSED_NUMBER, 'checkout'],
  roomAvailable: [PARSED_NUMBER, 'roomstate_changed_available'],
  roomUrgentCleaning: [PARSED_NUMBER, 'roomstate_changed_urgentCleaning'],
  reservationExpiredLodge: [PARSED_NUMBER, 'reservation_expired_lodge'],
  reservationExpiredRent: [PARSED_NUMBER, 'reservation_expired_rent'],
  needCleaningStarted: [PARSED_NUMBER, 'cleaning_started'],
  kioskConnected: ['kiosk_connected'],
  kioskConnectionClosed: ['kiosk_connection_closed'],
  doorOpened: [PARSED_NUMBER, 'door_opened'],
  doorClosed: [PARSED_NUMBER, 'door_closed'],
  cleaningStarted: [PARSED_NUMBER, 'cleaning_started'],
  cleaningEnded: [PARSED_NUMBER, 'cleaning_ended'],
  urgentCleaningOrdered: [PARSED_NUMBER, 'room_urgentCleaning_ordered'],
  needCleaningOrdered: [PARSED_NUMBER, 'cleaning_ordered'],
  roomGuestReenter: [PARSED_NUMBER, 'room_guest_reenter'],
  roomUnknownEntrance: [PARSED_NUMBER, 'room_unknown_entrance'],
  roomSelected: [PARSED_NUMBER, 'room_selected_in_kiosk'],
  roomUnselected: [PARSED_NUMBER, 'room_unselected_in_kiosk'],
  roomCarRequested: [PARSED_NUMBER, 'room_card_requested'],
};

class AudioPlayer {
  constructor({ host }: { host: string}) {
    this.host = host;
  }

  refineAudioPresetFromRoomData = (preset, roomData) => preset.map((audioName) => {
    if (audioName === PARSED_NUMBER) {
      const parsedRoomNumber = roomData.name?.split(/[^0-9]/).filter((val) => val).reduce((acc, cur) => (cur.length > acc.length ? cur : acc), '');

      if (!parsedRoomNumber || parsedRoomNumber.length > 4) {
        return 'room_default';
      }

      return `room_${parsedRoomNumber}`;
    }

    return audioName;
  })

  playAudioByRoomState = async (beforeRoomData, roomData) => {
    const { calculatedState } = roomData || {};
    const { calculatedState: beforeState, currentReservation: beforeReservation } = beforeRoomData || {};

    if (/cleaning/.test(beforeState) && !/cleaning/.test(calculatedState)) {
      await this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.cleaningEnded, roomData));
    }

    switch (true) {
      case (/expired|needClean/i.test(calculatedState) && /using/i.test(beforeState)): {
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomCheckOut, roomData));
      }
      case (/reserved/i.test(calculatedState)): {
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomAssigned, roomData));
      }
      case (/reserved/.test(beforeState) && /using/.test(calculatedState)): {
        if (calculatedState === 'usingRent') {
          return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomCheckedInRent, roomData));
        }
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomCheckedInLodge, roomData));
      }
      case (calculatedState === 'usingUnknown'): {
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomUnknownEntrance, roomData));
      }
      case (/using/.test(calculatedState)): {
        if (calculatedState === 'usingRent') {
          return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomCheckedInRent, roomData));
        }
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomCheckedInLodge, roomData));
      }
      case (/left/.test(calculatedState)): {
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomGuestGoOut, roomData));
      }
      case (/cleaning/.test(calculatedState)): {
        if (beforeState === 'needCleaning') {
          return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.needCleaningStarted, roomData));
        }
        if (beforeState === 'urgentCleaning') {
          return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomUrgentCleaning, roomData));
        }
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.cleaningStarted, roomData));
      }
      case (calculatedState === 'needCleaning'): {
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.needCleaningOrdered, roomData));
      }
      case (calculatedState === 'urgentCleaning'): {
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.urgentCleaningOrdered, roomData));
      }
      case (/expired/.test(calculatedState)): {
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomGuestLeft, roomData));
      }
      case (calculatedState === 'usingExpired'): {
        if (beforeReservation?.type === 'rent') {
          return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.reservationExpiredRent, roomData));
        }
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.reservationExpiredLodge, roomData));
      }
      case (calculatedState === 'available'): {
        if (beforeState === 'selected') {
          return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomUnselected, roomData));
        }
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomAvailable, roomData));
      }
      case (calculatedState === 'selected'): {
        return this.playAudioSet(this.refineAudioPresetFromRoomData(AUDIO_PRESET.roomSelected, roomData));
      }
      default: {
        return console.warn(`Unknown calculatedState found. failed to play audio. calculatedState: ${calculatedState}`);
      }
    }
  }

  preloadAudio = (audioName: string): Promise<HTMLAudioElement> => new Promise((resolve, reject) => {
    const audioLoader = new Audio(`${this.host}/${audioName}.mp3`);

    audioLoader.oncanplaythrough = () => resolve(audioLoader);
    audioLoader.onerror = reject;
  })

  playThrough = (player: HTMLAudioElement) => new Promise((resolve, reject) => {
    if (this.currentPlayer) {
      this.stopCurrentPlayer();
    }

    player.addEventListener('ended', () => {
      this.currentPlayer = null;
      this.stopCurrentPlayer = null;
      resolve(player);
    });

    player.addEventListener('error', (error) => {
      reject(error);
    });

    player.play();

    this.currentPlayer = player;
    this.stopCurrentPlayer = () => {
      player.pause();
      reject();
    };
  })

  playAudioSet = async (audioList: Array<string>) => {
    if (localStorage.getItem(CONSTANTS.SETTINGS.MUTE_MASTER_SOUND)) {
      return;
    }

    try {
      if (this.currentPlayer) {
        this.stopCurrentPlayer();
      }

      const audioPlayers = await Promise.all(audioList.map(this.preloadAudio));

      for (const player of audioPlayers) {
        await this.playThrough(player); // eslint-disable-line
      }
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.log(err);
      }
    }
  }
}

const audioPlayer = new AudioPlayer({ host: AUDIO_CLOUDFRONT_HOST });

window.audioPlayer = audioPlayer;

window.AUDIO_PRESET = AUDIO_PRESET;

export default audioPlayer;
