import { gql } from '@apollo/client';
import { connectionTemplate } from '.';

export const ChildPaymentFragment = gql`
  fragment ChildPaymentFragment on Payment {
      id
      isFullyPaid
      isCancelled
      reservationType
      type
      status
      createdBy
      phone
      amount
      outstandingAmount
      commissionAmount
      transactionId
      approvalId
      cardNumber
      parentPaymentId
      merchantNumber
      purpose
      issuedCompany
      buyerCompany
      createdAt
      useStartAt
      displayMessage
  }
`;

export const PaymentFragment = gql`
  fragment PaymentFragment on Payment {
    id
    isFullyPaid
    isCancelled
    reservationType
    purpose
    type
    status
    createdBy
    phone
    totalAmount
    outstandingAmount
    commissionAmount
    totalOutstandingAmount
    amount
    transactionId
    approvalId
    cardNumber
    merchantNumber
    issuedCompany
    buyerCompany
    createdAt
    useStartAt
    purpose
    displayMessage
    parentPaymentId
    parentPayment {
      id
      reservation {
        id
        number
        guestName
        phone
        type
        useStartAt
        useExpireAt
        useVehicle
        vehicleNumber
        roomType {
          id
          alias
          name
        }
        room {
          id
          name
        }
      }
    }
    childPayments {
      ...ChildPaymentFragment
    }
    mileage {
      id
      phone
      amount
    }
  }

  ${ChildPaymentFragment}
`;

export const CouponFragment = gql`
  fragment CouponFragment on Coupon {
    id
    name
    code
    couponType
    discountType
    discount
    maxDiscountAmount
    maxUsableCount
    minUsableAmount
    isExhausted
    expireAt
    mask
    accommodationId
    usableCount
  }
`;

export const addPaymentsMutation = gql`
  mutation addPayments($input: [AddPaymentInput!]!, $accommodationId: ID!) {
  addPayments (input: $input, accommodationId: $accommodationId) {
    id
  }
}
`;

export const cancelPaymentMutation = gql`
  mutation cancelPayment($paymentId: ID!) {
  cancelPayment(paymentId: $paymentId) {
    id
    isCancelled
    status
    parentPayment {
      id
      amount 
      totalAmount
    }
  }
}
`;

export const modifyPaymentMutation = gql`
  mutation modifyPayment($input: ModifyPaymentInput!) {
    modifyPayment(input: $input) {
      id
      isCancelled
      amount
      outstandingAmount
      commissionAmount
      totalOutstandingAmount
      childPayments {
        ...ChildPaymentFragment
      }
      type
      phone
    }
  }

  ${ChildPaymentFragment}
`;

export const resolveOutstandingPaymentMutation = gql`
  mutation resolveOutstandingPayment($paymentId: ID!, $resolvePaymentInput: AddPaymentInput!) {
    resolveOutstandingPayment(paymentId: $paymentId, resolvePaymentInput: $resolvePaymentInput) {
      ...PaymentFragment
    }
  }

  ${PaymentFragment}
`;

export const getAccommodationPayments = gql`
  query getAccommodationPayments ($accommodationId: ID!, $filter: PaymentQueryFilter!, $first: Int, $after: String) {
    getAccommodationPayments(first: $first, after: $after, accommodationId: $accommodationId, filter: $filter) {
      ${connectionTemplate`
        ...PaymentFragment
        reservation {
          id
          number
          guestName
          phone
          type
          useStartAt
          useExpireAt
          useVehicle
          vehicleNumber
          platform
          roomType {
            id
            alias
            name
          }
          room {
            id
            name
          }
        }
      `}
    }
  }
  ${PaymentFragment}
`;

export const aggregatePaymentsQuery = gql`
  query aggregatePayments ($accommodationId: ID!, $from: Date!, $to: Date!, $dateType: String) {
    aggregatePayments(
      accommodationId: $accommodationId
      from: $from
      to: $to
      dateType: $dateType
    ) {
      roomTypeId
      type
      createdBy
      amount
      outstandingAmount
      count
      reservationType
    }
  }
`;

export const issueCouponMutation = gql`
  mutation issueCoupon($input: AddCouponInput!) {
    issueCoupon(input: $input) {
      ...CouponFragment
    }
  }
  ${CouponFragment}
`;

export const expireCouponMutation = gql`
  mutation expireCoupon($code: ID!) {
    expireCoupon(code: $code) {
      ...CouponFragment
    }
  }
  ${CouponFragment}
`;

export const getAccommodationCouponsQuery = gql`
query getAccommodationCoupons ($accommodationId: ID!, $first: Int, $after: String) {
    getAccommodationCoupons(first: $first, after: $after, accommodationId: $accommodationId) {
      edges {
        node {
          ...CouponFragment
          histories {
            id
            status
            discountAmount
            purchaseAmount
            ipAddress
            usageHash
            usedAt
            createdAt
          }
        }
      }
    }
  }
  ${CouponFragment}
`;
