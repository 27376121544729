import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const SPLIT_POINT = '@splitpoint@';

const InnerTranslation = ({
  children,
  values,
  fallback,
  namespace,
}: { children: String, namespace: Object, values: Array, fallback: any } = {}) => {
  const { t } = useTranslation(namespace);
  let translationTarget = children;
  if (children?.join) {
    translationTarget = children.join('');
  }
  let translated = t(translationTarget?.replace?.(/\./g, '') || '');

  const splittedValues = [];

  if (values) {
    values.map((value, index) => {
      if (typeof value === 'object') {
        splittedValues.push(value);
        translated = translated.replace(
          new RegExp(`\\$\\{${index + 1}\\}`, 'g'),
          SPLIT_POINT,
        );
      } else {
        translated = translated.replace(
          new RegExp(`\\$\\{${index + 1}\\}`, 'g'),
          value,
        );
      }
    });
  }

  if (splittedValues.length) {
    translated = translated.split(SPLIT_POINT);

    splittedValues.forEach((value, index) => {
      translated.splice((index + 1) * 2 - 1, 0, value);
    });
  }

  if (translated === translationTarget && fallback) {
    return fallback;
  }

  return (
    <>
      {
      translated?.map?.((value, idx) => (
        <React.Fragment key={idx}>{value}</React.Fragment> // eslint-disable-line
      ))
      || translated
      }
    </>
  );
};

const Translated = ({
  children,
  namespace,
  values,
  fallback,
}: {
  children: String,
  namespace: Object,
  values: Array,
  fallback: String | React.ComponentElement,
} = {}) => (
  <Suspense fallback={fallback || '　'}>
    <InnerTranslation fallback={fallback} values={values} namespace={namespace}>
      {children}
    </InnerTranslation>
  </Suspense>
);

export default Translated;
