import MomentUtils from '@date-io/moment';
import moment from 'moment';

export class NoYearUtils extends MomentUtils {
  constructor(props) {
    super(props);

    this.getMeridiemText = (ampm) => (ampm === 'am' ? moment.localeData().meridiem(0) : moment.localeData().meridiem(13));

    const _originFormat = this.format.bind(this);

    this.format = (date, formatString) => {
      if (formatString === 'monthAndYear' || formatString === 'MMMM YYYY') {
        return _originFormat(date, 'MMM');
      }

      if (formatString === 'ddd, MMM D') {
        return _originFormat(date, 'MMM D일');
      }

      return _originFormat(date, formatString);
    };
  }
}
