import { ApolloClient, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';

import { getMainDefinition, Observable, relayStylePagination } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { ApolloLink, split } from '@apollo/client/link/core';
// import { fromPromise } from '@apollo/client/link/utils';
import { createUploadLink } from 'apollo-upload-client';
import { getErrorCode } from 'helpers';
import { API_HOST, WS_API_HOST } from './constants';

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getAccommodationNotifications: relayStylePagination(['accommodationId', 'filter']),
        getAccommodationPayments: relayStylePagination(['accommodationId', 'filter']),
        getAccommodationReservations: relayStylePagination(['accommodationId', 'filter']),
        getAccommodationMileages: relayStylePagination(['accommodationId', 'filter']),
        getAccommodationMileageUsers: relayStylePagination(['accommodationId', 'filter']),
        getAccommodationUsers: relayStylePagination(['accommodationId', 'filter']),
        getUserLogs: relayStylePagination(['accommodationId', 'relatedId']),
        getAccommodationCoupons: relayStylePagination(['accommodationId']),
        getArticles: relayStylePagination([]),
        getMyUserNotifications: relayStylePagination(['accommodationId']),
      },
    },
    Accommodation: {
      fields: {
        upcomingReservations: {
          merge: (existing, incoming) => incoming,
        },
      },
    },
    Kiosk: {
      fields: {
        options: {
          merge: (existing, incoming) => ({ ...existing, ...incoming }),
        },
      },
    },
    RoomType: {
      fields: {
        rooms: {
          merge: (existing, incoming) => incoming,
        },
      },
    },
  },
});

const requestLink = new ApolloLink(
  (operation, forward) => new Observable((observer) => {
    let handle;
    Promise.resolve(operation)
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  }),
);

const wsLink = new WebSocketLink({
  uri: `${WS_API_HOST}/graphql`,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => ({
      // Authorization: `Bearer ${authHandler.accessToken}`,
    }),
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink,
  createUploadLink({
    uri: `${API_HOST}/graphql`,
  }),
);

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({
      graphQLErrors, networkError,
    }) => {
      if (graphQLErrors) {
        // let needRefreshToken;

        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );

          // if (message.includes('401 Access denied') && /subscription/i.test(path)) {
          //   needRefreshToken = true;
          // }
        });

        // if (needRefreshToken) {
        //   console.log('Refreshing token due to subscription authorize error.');
        //   return fromPromise(authHandler.refreshAccessToken())
        //     .filter((value) => Boolean(value))
        //     .flatMap(() => location.reload()); // eslint-disable-line
        // }

        if (
          graphQLErrors.length === 1
          && getErrorCode(graphQLErrors[0].message) === 'ERR_NEED_OTP'
        ) {
          // return fromPromise(authHandler.openOtpPopup())
          //   .filter((value) => Boolean(value))
          //   .flatMap((otp) => {
          //     if (operation?.variables?.input) {
          //       operation.variables.input.otp = otp; // eslint-disable-line
          //     } else {
          //       operation.variables.otp = otp; // eslint-disable-line
          //     }
          //     return forward(operation);
          //   });
        }
        // authHandler.refreshAccessToken();
      }
      if (networkError) {
        // if (networkError.result?.message === 'jwt expired' || /Token verification failed/.test(networkError.result?.message)) {
        //   return fromPromise(authHandler.refreshAccessToken())
        //     .filter((value) => Boolean(value))
        //     .flatMap(() => forward(operation));
        // }
        console.log('[Network error]: ', networkError);
      }
    }),
    requestLink,
    splitLink,
  ]),
  cache: apolloCache,
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;
