import React, { useCallback, useEffect } from 'react';

function withCloseOnPopState(Component) {
  return ({ onClose, open, ...restProps }) => {
    const modifiedOnClose = useCallback(() => {
      history.replaceState({}, null, ''); // eslint-disable-line

      return onClose();
    }, [onClose]);

    useEffect(() => {
      if (open) {
        history.replaceState({ __preventDefault: true }, null, ''); // eslint-disable-line
        history.pushState({}, null, ''); // eslint-disable-line

        window.addEventListener('popstate', modifiedOnClose);

        return () => {
          window.removeEventListener('popstate', modifiedOnClose);
        };
      }
      window.removeEventListener('popstate', modifiedOnClose);
    }, [open]);

    return <Component onClose={onClose} open={open} {...restProps} />;
  };
}

export default withCloseOnPopState;
