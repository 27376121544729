import styled from 'styled-components';
import React, { useRef } from 'react';
import {
  Button as MUIButton,
  Checkbox,
  ButtonProps,
  Fab,
} from '@material-ui/core';
import STYLED_MACROS from 'styles/styledMacros';

const LoaderButton = styled(MUIButton)`
  &[data-loading='true'] {
    opacity: 0.7;
  }

  &[data-variant='selected'] {
    background-color: rgba(255, 255, 255, 0.15);
    color: white;
  }
`;

export const AbsoluteFab = styled(Fab)`
  position: absolute;
  z-index: 1000;
  right: ${(props) => props.theme.spacing(3)}px;
  bottom: ${(props) => props.theme.spacing(3)}px;
`;

export const Button = ({
  loading,
  loadLabel,
  children,
  onClick,
  ...restProps
}: { loading: Boolean, loadLabel: any } & ButtonProps) => (
  <LoaderButton
    {...restProps}
    onClick={loading ? () => {} : onClick}
    data-loading={!!loading}
    data-variant={restProps['data-variant'] || restProps.variant}
  >
    {(loading && loadLabel) || children}
  </LoaderButton>
);

export const DarkButton = styled(Button)`
  background: ${STYLED_MACROS.BACKGROUND.DARK};
  color: white;
  font-family: ${STYLED_MACROS.TYPOGRAPHY.TITLE};
  font-size: 1rem;

  &:hover {
    background: ${STYLED_MACROS.BACKGROUND.DARK_HOVER};
  }
`;

export const ErrorButton = styled(Button)`
  background: ${STYLED_MACROS.PALETTE.ERROR.MAIN};
  color: white;

  &:hover {
    background: ${STYLED_MACROS.PALETTE.ERROR.HOVER};
  }

  &[data-variant='text'] {
    background: transparent;
    color: ${STYLED_MACROS.PALETTE.ERROR.MAIN};
    &:hover {
      background: transparent;
    }
  }
`;

export const SuccessButton = styled(Button)`
  background: ${STYLED_MACROS.PALETTE.SUCCESS.MAIN};
  color: white;

  &:hover {
    background: ${STYLED_MACROS.PALETTE.SUCCESS.HOVER};
  }
`;

export const FrequentlyButton = styled(Button)`
  background: ${({ backgroundColor }) =>
    backgroundColor || STYLED_MACROS.PALETTE.SECONDARY.MAIN};
  color: white;
  font-family: 'Montserrat', 'sans-serif';
  width: ${({ width }) => width};
  height: ${({ height }) => height}px;
  font-weight: ${({ weight }) => weight};
  margin-top: ${({ marginTop }) => marginTop}px;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  &:hover {
    background: ${STYLED_MACROS.PALETTE.SUCCESS.HOVER};
  }
  &:disabled {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const CheckBoxButtonWrapper = styled(DarkButton)`
  position: relative;
  flex-shrink: 0;
  margin-bottom: 8px;
  padding-left: 0;

  background: ${(props) =>
    props.checked
      ? STYLED_MACROS.BACKGROUND.DARK
      : STYLED_MACROS.BACKGROUND.DEFAULT};

  > span {
    justify-content: flex-start;
  }

  &[data-variant='text'] {
    background: none !important;
    margin-bottom: 0;
    padding-left: 0;
  }

  &[data-size='small'] {
    height: 32px;
    margin-bottom: 0;
    padding: 4px;

    > * {
      height: 24px;
    }

    > span > span {
      padding: 0;
      margin-right: 4px;
    }
  }
`;

export const CheckBoxButton = ({
  checked,
  onChange,
  children,
  onClick,
  variant,
  size,
  ...rest
}: {
  checked: Boolean,
  onChange: Function,
  onClick: Function,
  variant: 'default' | 'text',
  size: 'small' | 'default',
  children: React.ComponentElement,
}) => {
  const checkBoxRef = useRef();
  window.cbRef = checkBoxRef;
  return (
    <CheckBoxButtonWrapper
      data-variant={variant}
      data-size={size}
      checked={checked}
      onClick={(...args) => onClick?.(...args) || onChange?.(!checked)}
      {...rest}
    >
      <Checkbox
        disableRipple
        innerRef={checkBoxRef}
        checked={checked}
        onChange={() => {}}
        color="primary"
      />
      {children}
    </CheckBoxButtonWrapper>
  );
};
