import React, { useRef } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import NeedConfirmContext from 'context/ConfirmAction';
import {
  getAccommodationMetaInfoQuery,
  getAccommodationRoomsQuery,
  getAccommodationKiosksOptionsQuery,
  updateRoomStateQuery,
  publishAccommodationNotificaitonMutation,
  getAccommodationAudioOptionsQuery,
  getAccommodationMetaInfoMaidQuery,
} from 'graphql/accommodation';
import { getArticlesQuery } from 'graphql/article';
import {
  Debounce,
  destructSingleField,
  extractFloor,
  getErrorCode,
  hasNextPage,
  isAboveAuthority,
  onEnter,
  pageInfo,
  parseWingFromRoomName,
  restrictInputNumberOnly,
} from 'helpers';
import { CONSTANTS } from 'helpers/constants';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  InputAdornment,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { IndicatorTitleTypo, TitleTypo } from 'components/ui/Typographies';
import Translated from 'components/Translation';
import { DatePicker } from '@material-ui/pickers';
import { Today } from '@material-ui/icons';
import { Button, DarkButton, ErrorButton } from 'components/ui/Button';
import { useSnackbar } from 'notistack';
import { getAccommodationMileageSettingsQuery } from 'graphql/mileage';
import {
  getAccommodationReservationSettingsQuery,
  getReservationSettingByGuestSessionQuery,
} from 'graphql/reservations';
import { NoYearDatePicker } from 'components/ui/DatePicker';
import audioPlayer from 'helpers/audioPlayer';
import apolloClient, { apolloCache } from 'helpers/apolloClient';
import rafSchd from 'raf-schd';
import { useWindowResize } from 'context/WindowResize';
import { ConfirmDialogContent } from 'components/ui/Modal';
import { Autocomplete } from '@material-ui/lab';
import {
  getAccommodationOtaListQuery,
  getAccommodationPackagesQuery,
  getOtaPriceContractionsQuery,
} from 'graphql/ota';
import { aggregatePaymentsQuery } from 'graphql/payment';
import authHandler from 'helpers/authHandler';
import { getSingleReservationByGuestSessionQuery } from 'graphql/thirdParty';
import { useRecoilState } from 'recoil';
import { permanentStateAtom } from 'helpers/atoms';
import {
  getConciergeServiceSettingByGuestSessionQuery,
  getConciergeWorkSettingsQuery,
} from 'graphql/concierge';

export { useSnackbar } from 'notistack';

const {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} = require('react');

export const useDebounce = () => {
  const [asyncDebouncer] = useState(new Debounce());

  return asyncDebouncer;
};

export const useMyAuthority = (accId) => {
  const { id: myId } = authHandler?.user || {};
  const { accommodationId } = useParams();

  const { data } = useQuery(
    ((accId || accommodationId) && getAccommodationMetaInfoMaidQuery) ||
      getArticlesQuery,
    {
      variables: {
        id: accommodationId || accId,
      },
    },
  );

  if (!accommodationId && !accId) {
    return null;
  }

  return destructSingleField(data)?.employees?.find(
    (employee) => employee.id === myId,
  )?.authority;
};

export const usePointerSimulator = () => {
  const pointElementRef = useRef();

  const simulateDrag = useCallback((event) => {
    if (event?.__isTouchConverted) {
      const pointElement = document.elementFromPoint(
        event.clientX,
        event.clientY,
      );
      if (pointElementRef.current !== pointElement) {
        const leaveEvent = new Event('mouseleave', { bubbles: true });
        pointElementRef.current?.dispatchEvent?.(leaveEvent);
        pointElementRef.current = pointElement;
        const enterEvent = new Event('mouseenter', { bubbles: true });
        pointElementRef.current.dispatchEvent?.(enterEvent);
      }
    }
  }, []);

  const simulateEnd = useCallback((event) => {
    if (event?.__isTouchConverted) {
      if (pointElementRef.current) {
        const mouseUpEvent = new Event('mouseup', { bubbles: true });
        pointElementRef.current.dispatchEvent(mouseUpEvent);
      }
    }
  });

  return [{ simulateDrag, simulateEnd }, { pointElementRef }];
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const throttledHandleResize = useCallback(rafSchd(handleResize), []);

  useEffect(() => {
    window.addEventListener('resize', throttledHandleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useDeviceQuery = (device) => {
  useWindowResize();
  const isQueriedDevice = useMediaQuery(
    device === 'tiny'
      ? `(max-width: ${CONSTANTS.TINY_DEVICE_WIDTH}px)`
      : (theme) =>
          theme.breakpoints.down(
            (device === 'phone' && 'xs') ||
              (device === 'mobile' && 'sm') ||
              (device === 'compact' && 'md') ||
              'xl',
          ),
  );

  return isQueriedDevice;
};

const fragmentMemo = {};

const memoizedFragment = (tag, type) => {
  if (!fragmentMemo[tag]) {
    const replacedTag = tag
      .replace('__fragmentName__', `${type}${new Date().getTime()}`)
      .replace('__type__', type);
    fragmentMemo[tag] = gql(replacedTag);
  }

  return fragmentMemo[tag];
};

export const useRefetchRoomData = () => {
  const refetchRoomData = async (id) => {
    try {
      const beforeRoomData = apolloCache.readFragment({
        id: `Room:${id}`,
        fragment: memoizedFragment(
          `
          fragment __fragmentName__ on __type__ {
            id
            calculatedState
            currentReservation {
              id
              type
            }
          }
        `,
          'Room',
        ),
      });

      await new Promise((resolve) => setTimeout(resolve, 500));
      const queryResult = await apolloClient.query({
        query: updateRoomStateQuery,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      });

      const {
        data: { getSingleRoom: afterRoomData },
      } = queryResult;

      if (beforeRoomData?.calculatedState !== afterRoomData?.calculatedState) {
        audioPlayer.playAudioByRoomState(beforeRoomData, afterRoomData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return [refetchRoomData, { memoizedFragment }];
};

export const useAccommodationMetaInfo = (useRefetch) => {
  const { accommodationId } = useParams();

  const myAuthority = useMyAuthority();

  const { data, refetch } = useQuery(
    (isAboveAuthority(myAuthority, 'STAFF') && getAccommodationMetaInfoQuery) ||
      getAccommodationMetaInfoMaidQuery,
    {
      variables: {
        id: accommodationId,
      },
    },
  );

  const metaData = useMemo(() => {
    const tempData = { ...destructSingleField(data) };

    if (tempData.options) {
      tempData.options.forEach(({ key, value }) => {
        if (!tempData[key]) {
          tempData[key] = value;
        }
      });
    }

    return tempData;
  }, [data]);

  if (useRefetch) {
    return [metaData, refetch];
  }

  return metaData;
};

export const useAccommodationRooms = () => {
  const { accommodationId } = useParams();
  const { data: roomData } = useQuery(getAccommodationRoomsQuery, {
    variables: {
      id: accommodationId,
    },
  });

  const { rooms } = destructSingleField(roomData);

  return rooms || [];
};

export const useAccommodationAudioOptions = (key) => {
  const { accommodationId } = useParams();

  const { data, refetch } = useQuery(getAccommodationAudioOptionsQuery, {
    variables: {
      id: accommodationId,
      ...(key && { key }),
    },
  });

  return [destructSingleField(data, []), { refetch }];
};

export const useOnFetchMore =
  ({ fetchMore, data, variables }) =>
  () => {
    if (!hasNextPage(data)) {
      return null;
    }
    return fetchMore({
      variables: {
        ...variables,
        after: pageInfo(data)?.endCursor,
      },
    });
  };

export const useInput = (initialInput = {}) => {
  const [initState, setInitState] = useState(initialInput);
  const [input, setInput] = useState(initialInput);

  const initialize = useCallback(() => {
    setInput(initState);
  }, [initState]);

  const onChangeInput = useCallback(
    (key, value) => {
      const keys = key.split('.');

      if (keys.length) {
        const newInput = { ...input };

        let targetInputParent = newInput;
        let originInputParent = input;
        let targetKey;

        keys.forEach((_key, idx) => {
          if (idx !== keys.length - 1) {
            targetInputParent[_key] = {
              ...input[_key],
            };

            originInputParent = originInputParent[_key];
            targetInputParent = targetInputParent[_key];
          } else {
            targetKey = _key;
          }
        });

        targetInputParent[targetKey] = value;

        setInput(newInput);

        return newInput;
      }

      const newInput = {
        ...input,
        [key]: value,
      };
      setInput(newInput);

      return newInput;
    },
    [input],
  );

  const isChanged = useMemo(() => {
    let _isChanged = false;

    const recurseCompare = (currentDepth, operand) => {
      if (_isChanged) return;

      // console.log(currentDepth, operand);

      if (typeof currentDepth !== typeof operand) {
        _isChanged = true;
        return;
      }

      if (currentDepth && typeof currentDepth === 'object') {
        if (
          Object.getPrototypeOf(currentDepth).constructor.name === 'Object' ||
          Array.isArray(currentDepth)
        ) {
          if (
            Object.keys(currentDepth).length !== Object.keys(operand).length
          ) {
            _isChanged = true;
            return;
          }
          Object.keys(currentDepth).forEach((key) => {
            recurseCompare(currentDepth[key], operand[key]);
          });

          return;
        }

        if (moment.isDate(currentDepth) || moment.isMoment(currentDepth)) {
          if (!moment(currentDepth).isSame(operand)) {
            _isChanged = true;
          }
          return;
        }
      }

      if (currentDepth !== operand) {
        _isChanged = true;
      }
    };
    recurseCompare(input, initState);
    return _isChanged;
  }, [input, initState]);

  return [
    input,
    onChangeInput,
    {
      setInput,
      initialize,
      setInitState,
      isChanged,
      initState,
    },
  ];
};

export const useFilter = (
  initialFilter,
  initialIndirectFilter,
  initialDebouncedFilter,
  { debouncerTimeout = 500 } = {},
) => {
  const debouncer = useDebounce();
  const [initState] = useState({
    initialFilter,
    initialIndirectFilter,
    initialDebouncedFilter,
  });
  const [filter, _setFilter] = useState({
    ...initialFilter,
    ...initialDebouncedFilter,
  });
  const [indirectFilter, setIndirectFilter] = useState(initialIndirectFilter);
  const [debouncedFilter, setDebouncedFilter] = useState(
    initialDebouncedFilter,
  );

  const setFilter = useCallback(
    (nextState) => {
      _setFilter({
        ...indirectFilter,
        ...nextState,
      });
    },
    [indirectFilter],
  );

  const initializeFilter = useCallback(() => {
    const {
      initialFilter: initFilter,
      initialDebouncedFilter: initDebounced,
      initialIndirectFilter: initIndirect,
    } = initState;

    setFilter(initFilter);
    setIndirectFilter(initIndirect);
    setDebouncedFilter(initDebounced);
  }, [initState]);

  const onChangeFilter = useCallback(
    (key, value) => {
      setFilter({
        ...filter,
        [key]: value,
      });
    },
    [filter],
  );

  const onChangeIndirectFilter = useCallback(
    (key, value) => {
      setIndirectFilter({
        ...indirectFilter,
        [key]: value,
      });
    },
    [indirectFilter],
  );

  const onChangeDebouncedFilter = useCallback(
    async (key, value) => {
      setDebouncedFilter({
        ...debouncedFilter,
        [key]: value,
      });

      if (await debouncer.asyncDebounce(debouncerTimeout)) {
        setFilter({
          ...filter,
          [key]: value,
        });
      }
    },
    [debouncedFilter, filter, debouncer],
  );

  return {
    filter,
    indirectFilter,
    debouncedFilter,
    onChangeFilter,
    onChangeIndirectFilter,
    setFilter,
    setIndirectFilter,
    setDebouncedFilter,
    initializeFilter,
    onChangeDebouncedFilter,
  };
};

export const useConfirmAction = ({
  count = 2,
  timeout = 5000,
  onDenied,
  disableConfirm,
  dialog,
}) => {
  let confirmAction;

  const { setConfirmDialog } = useContext(NeedConfirmContext);
  const [actionCount, setActionCount] = useState(null);
  const [confirmTimeout, setConfirmTimeout] = useState(null);

  if (dialog) {
    confirmAction = async (customMessage) => {
      const { title, confirmLabel, variant, cancelLabel } = dialog;

      let { content } = dialog;

      if (customMessage) {
        content += customMessage;
      }

      let actionResolver;

      const actionAwaiter = new Promise((resolve) => {
        actionResolver = resolve;
      });

      const onConfirmOrCancel = (confirm) => {
        actionResolver(confirm);
        setConfirmDialog(null);
      };

      setConfirmDialog({
        open: true,
        title,
        content,
        confirmLabel,
        cancelLabel,
        variant,
        onConfirm: () => onConfirmOrCancel(true),
        onCancel: () => onConfirmOrCancel(false),
      });

      return actionAwaiter;
    };
  } else {
    confirmAction = async () => {
      if (disableConfirm) return true;
      const newActionCount = actionCount + 1;

      clearTimeout(confirmTimeout);

      if (newActionCount === count) {
        setActionCount(0);
        return true;
      }

      if (onDenied) {
        onDenied(newActionCount);
      }

      setConfirmTimeout(
        setTimeout(() => {
          setActionCount(0);
        }, timeout),
      );
      setActionCount(newActionCount);
    };
  }

  return confirmAction;
};

export const useNeedConfirm = (...dependencies) => {
  const { needConfirm, setNeedConfirm } = useContext(NeedConfirmContext);

  useEffect(() => {
    setNeedConfirm(!!dependencies.filter((val) => val).length);
  }, [...dependencies]);

  return { needConfirm, setNeedConfirm };
};

export const useTimes = (initialTimes) => {
  const [mappedTimes, setMappedTimes] = useState(
    initialTimes ||
      new Array(48).fill().map((_, idx) => ({
        start: idx * 30,
        value: CONSTANTS.PRICE_TIME_TYPES.UNSET,
      })),
  );

  const applyChangeTimes = (selectedTimes, value) => {
    const newTimes = mappedTimes.slice();

    newTimes.splice(
      newTimes.findIndex((time) => time.start === selectedTimes[0].start),
      selectedTimes.length,
      ...selectedTimes.map((time) => ({ ...time, value })),
    );

    setMappedTimes(newTimes);
  };

  return [mappedTimes, setMappedTimes, applyChangeTimes];
};

export const useTimesTemplate = (mappedRentTimes, mappedLodgeTimes) => {
  const { accommodationId } = useParams();
  const [openAddTimesTemplate, setOpenAddTimesTemplate] = useState(false);
  const [newTimesTemplateName, setNewTimesTemplateName] = useState('');
  const [timesTemplates, setTimesTemplates] = useState({});
  const [selectedTimesTemplate, setSelectedTimesTemplate] = useState('');

  useEffect(() => {
    let savedTimesTemplate;

    try {
      savedTimesTemplate = JSON.parse(
        localStorage.getItem(`${CONSTANTS.TIMES_TEMPLATE}.${accommodationId}`),
      );
    } catch (err) {
      console.log(err);
    } finally {
      if (!savedTimesTemplate) {
        savedTimesTemplate = {};
      }
    }

    setTimesTemplates(savedTimesTemplate);
  }, []);

  const applyAddTimesTemplate = (settings) => {
    const newTimesTemplates = {
      ...timesTemplates,
      [newTimesTemplateName]: {
        rent: mappedRentTimes,
        lodge: mappedLodgeTimes,
        settings,
      },
    };

    setTimesTemplates(newTimesTemplates);

    localStorage.setItem(
      `${CONSTANTS.TIMES_TEMPLATE}.${accommodationId}`,
      JSON.stringify(newTimesTemplates),
    );

    setOpenAddTimesTemplate(false);
    setNewTimesTemplateName('');
  };

  const removeSelectedTimesTemplate = () => {
    const newTimesTemplates = {
      ...timesTemplates,
    };

    delete newTimesTemplates[selectedTimesTemplate];

    setTimesTemplates(newTimesTemplates);

    localStorage.setItem(
      `${CONSTANTS.TIMES_TEMPLATE}.${accommodationId}`,
      JSON.stringify(newTimesTemplates),
    );

    setOpenAddTimesTemplate(false);
    setNewTimesTemplateName('');
    setSelectedTimesTemplate('');
  };

  return {
    timesTemplates,
    applyAddTimesTemplate,
    removeSelectedTimesTemplate,
    openAddTimesTemplate,
    setOpenAddTimesTemplate,
    newTimesTemplateName,
    setNewTimesTemplateName,
    setSelectedTimesTemplate,
    selectedTimesTemplate,
  };
};

export const useSimpleDialog = ({
  title,
  content,
  onSave,
  onClose,
  inputFields = [],
  listenChange,
  deleteLabel,
  onDelete,
  saveLabel,
  loadLabel,
  dependencies = [],
  dialogProps,
}) => {
  const initialInput = useMemo(
    () =>
      inputFields.reduce((acc, cur) => {
        const newAcc = { ...acc };

        newAcc[cur.key] =
          cur.initialValue || (cur.type === 'date' ? moment() : '');

        return newAcc;
      }, {}),
    dependencies || [],
  );

  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [input, onChangeInput, { isChanged, setInput, setInitState }] =
    useInput(initialInput, { listenChange });

  const onClickSave = async () => {
    setIsSaving(true);
    await onSave(input, open);
    setIsSaving(false);
  };

  const onClickDelete = async () => {
    setIsSaving(true);
    await onDelete(input);
    setIsSaving(false);
  };

  useEffect(() => {
    setInput(initialInput);
    setInitState(initialInput);
  }, [initialInput]);

  const renderInputField = (inputField, idx) => {
    const { type, label, key, saveOnEnterKey, adornment, style, placeholder } =
      inputField;
    const needGutter = !!idx;
    switch (inputField.type) {
      case 'select': {
        const { valueIndex, labelIndex } = inputField;
        return (
          <React.Fragment key={key}>
            <IndicatorTitleTypo
              style={{ marginTop: needGutter ? 16 : 0 }}
              variant="body1"
              gutterBottom
            >
              {label}
            </IndicatorTitleTypo>
            <TextField
              variant="filled"
              margin="dense"
              InputProps={{ disableUnderline: true }}
              select
              label="선택안함"
              fullWidth
              onChange={(event) => onChangeInput(key, event.target.value)}
              value={input[key]}
            >
              <MenuItem value="">선택안함</MenuItem>
              {inputField.items?.map((item) => {
                const itemValue = item[valueIndex];
                const itemLabel = labelIndex ? item[labelIndex] : itemValue;

                return (
                  <MenuItem value={itemValue} key={itemValue}>
                    {itemLabel}
                  </MenuItem>
                );
              })}
            </TextField>
          </React.Fragment>
        );
      }
      case 'autoComplete': {
        const { valueIndex, labelIndex } = inputField;
        return (
          <React.Fragment key={key}>
            <IndicatorTitleTypo
              style={{ marginTop: needGutter ? 16 : 0 }}
              variant="body1"
              gutterBottom
            >
              {label}
            </IndicatorTitleTypo>
            <Autocomplete
              options={inputField.items}
              value={input[key]}
              getOptionLabel={(option) => option[labelIndex] || '선택안함'}
              getOptionSelected={(option, value) =>
                value ? option[valueIndex] === input[key][valueIndex] : true
              }
              renderOption={(option) => option[labelIndex]}
              onChange={(event, changedOption) => {
                onChangeInput(key, changedOption);
              }}
              disableClearable
              noOptionsText={<Translated>검색 결과 없음</Translated>}
              style={{ width: 240, marginBottom: 8 }}
              ListboxProps={{ style: { width: '100%' } }}
              renderInput={(params) => (
                <TextField
                  helperText={
                    <Translated>입력하여 검색하거나 선택하세요.</Translated>
                  }
                  {...params}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                  label={<Translated>호실</Translated>}
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          </React.Fragment>
        );
      }
      case 'text': {
        return (
          <React.Fragment key={key}>
            <IndicatorTitleTypo
              style={{ marginTop: needGutter ? 16 : 0 }}
              variant="body1"
              gutterBottom
            >
              {label}
            </IndicatorTitleTypo>
            <TextField
              variant="outlined"
              fullWidth
              margin="dense"
              placeholder={label}
              value={input[key]}
              onKeyDown={saveOnEnterKey ? onEnter(onClickSave) : () => {}}
              onChange={(event) => onChangeInput(key, event.target.value)}
            />
          </React.Fragment>
        );
      }
      case 'number': {
        return (
          <React.Fragment key={key}>
            <IndicatorTitleTypo
              style={{ marginTop: needGutter ? 16 : 0 }}
              variant="body1"
              gutterBottom
            >
              {label}
            </IndicatorTitleTypo>
            <TextField
              variant="outlined"
              fullWidth
              margin="dense"
              placeholder={label}
              value={input[key]}
              onKeyDown={saveOnEnterKey ? onEnter(onClickSave) : () => {}}
              onChange={restrictInputNumberOnly({
                numeric: false,
                onChange: (newValue) => onChangeInput(key, newValue),
              })}
            />
          </React.Fragment>
        );
      }
      case 'amount': {
        return (
          <React.Fragment key={key}>
            <IndicatorTitleTypo
              style={{ marginTop: needGutter ? 16 : 0 }}
              variant="body1"
              gutterBottom
            >
              {label}
            </IndicatorTitleTypo>
            <TextField
              variant="outlined"
              fullWidth
              style={style}
              margin="dense"
              placeholder={placeholder || label}
              value={input[key]}
              onKeyDown={saveOnEnterKey ? onEnter(onClickSave) : () => {}}
              onChange={restrictInputNumberOnly({
                withComma: true,
                numeric: true,
                onChange: (newValue) => onChangeInput(key, newValue),
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment poisiton="end">
                    {adornment || '원'}
                  </InputAdornment>
                ),
              }}
            />
          </React.Fragment>
        );
      }
      case 'date': {
        return (
          <React.Fragment key={key}>
            <IndicatorTitleTypo
              style={{ marginTop: needGutter ? 16 : 0 }}
              variant="body1"
              gutterBottom
            >
              {label}
            </IndicatorTitleTypo>
            <DatePicker
              okLabel={<Translated>확인</Translated>}
              cancelLabel={<Translated>취소</Translated>}
              emptyLabel="선택안함"
              hideTabs
              format="MM월 DD일"
              value={input[key]}
              onChange={(newValue) => onChangeInput(key, newValue)}
              fullWidth
              views={['month', 'date']}
              style={{ marginBottom: 8 }}
              inputVariant="filled"
              InputProps={{
                disableUnderline: true,
                margin: 'dense',
                endAdornment: (
                  <InputAdornment poisiton="end">
                    <Today />
                  </InputAdornment>
                ),
              }}
            />
          </React.Fragment>
        );
      }
      case 'dateWithoutYear': {
        return (
          <React.Fragment key={key}>
            <IndicatorTitleTypo
              style={{ marginTop: needGutter ? 16 : 0, display: 'block' }}
              variant="body1"
              gutterBottom
            >
              {label}
            </IndicatorTitleTypo>
            <NoYearDatePicker
              okLabel={<Translated>확인</Translated>}
              cancelLabel={<Translated>취소</Translated>}
              emptyLabel="선택안함"
              hideTabs
              format="MM월 DD일"
              value={input[key]}
              onChange={(newValue) => onChangeInput(key, newValue)}
              fullWidth
              views={['month', 'date']}
              style={{ marginBottom: 8, display: 'flex' }}
              inputVariant="filled"
              InputProps={{
                disableUnderline: true,
                margin: 'dense',
                endAdornment: (
                  <InputAdornment poisiton="end">
                    <Today />
                  </InputAdornment>
                ),
              }}
            />
          </React.Fragment>
        );
      }
      case 'hide': {
        return null;
      }
      default: {
        throw new Error('Undefined input field: ', type);
      }
    }
  };

  const renderDialog = useCallback(() => (
    <Dialog open={!!open} onClose={onClose} PaperProps={dialogProps}>
      <DialogTitle>
        <TitleTypo>{title}</TitleTypo>
      </DialogTitle>
      <ConfirmDialogContent>
        {content}
        {inputFields.map(renderInputField)}
      </ConfirmDialogContent>
      <DialogActions>
        <DarkButton
          disableElevation
          variant="contained"
          onClick={() => {
            setOpen(false);
            return onClose && onClose();
          }}
          disabled={isSaving}
        >
          취소
        </DarkButton>
        {onDelete && (
          <ErrorButton
            disableElevation
            color="secondary"
            variant="contained"
            loading={isSaving}
            loadLabel={loadLabel}
            onClick={onClickDelete}
          >
            {deleteLabel || '삭제하기'}
          </ErrorButton>
        )}
        {onSave && (
          <Button
            disableElevation
            color="secondary"
            variant="contained"
            loading={isSaving}
            loadLabel={loadLabel}
            disabled={listenChange && !isChanged}
            onClick={onClickSave}
          >
            {saveLabel || '저장하기'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  ));

  return [
    setOpen,
    renderDialog,
    {
      isChanged,
      setInput,
      setInitState,
      openState: open,
    },
  ];
};

export const useNotifyFeedback = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notifySuccess = (message) =>
    enqueueSnackbar(message, { variant: 'success' });

  const notifyError = (err) => {
    console.error(err);

    if (err.force) {
      return enqueueSnackbar(err.message, { variant: 'error' });
    }
    if (err === 'UNSAVED_CHANGE') {
      return enqueueSnackbar(<Translated>ERR_UNSAVED_CHANGE</Translated>, {
        variant: 'error',
      });
    }

    if (/Validation notEmpty/.test(err?.message || err)) {
      return enqueueSnackbar(
        '누락된 입력값이 있습니다. 모든 입력을 완료해 주세요.',
        { variant: 'error' },
      );
    }

    if (/401 Access denied/.test(err?.message || err)) {
      return enqueueSnackbar('작업을 실행할 권한이 없습니다.', {
        variant: 'error',
      });
    }

    const errorCode = getErrorCode(err);

    switch (errorCode) {
      case '400': {
        return enqueueSnackbar(
          `잘못된 요청입니다. 입력을 확인해 주세요. 에러코드: ${errorCode}`,
          { variant: 'error' },
        );
      }
      case 'ERR_DUPLICATED_DATA_FOUND': {
        return enqueueSnackbar(
          `중복된 데이터가 존재합니다. 에러코드: ${errorCode}`,
          { variant: 'error' },
        );
      }
      case 'ERR_DATA_NOT_FOUND_ON_ID': {
        return enqueueSnackbar(
          `유효하지 않은 정보입니다. 에러코드: ${errorCode}`,
          { variant: 'error' },
        );
      }
      default: {
        return enqueueSnackbar(
          `알 수 없는 에러가 발생했습니다. 에러코드: ${errorCode}`,
          { variant: 'error' },
        );
      }
    }
  };

  return [notifySuccess, notifyError, { enqueueSnackbar }];
};

export const useMileageSettings = () => {
  const { accommodationId } = useParams();
  const {
    data: mileageSettingData,
    loading,
    error,
    ...rest
  } = useQuery(getAccommodationMileageSettingsQuery, {
    variables: {
      accommodationId,
    },
  });

  return [
    destructSingleField(mileageSettingData).mileageSettings || {},
    loading,
    error,
    rest,
  ];
};

export const useReservationSettings = (useQueryOptions) => {
  const { reservationId } = useParams();
  // eslint-disable-next-line no-use-before-define
  const [accommodationId] = usePermanentState(
    null,
    `${reservationId}_ACCOMMODATION_TOKEN`,
  );

  const {
    data: reservationSettingData,
    loading,
    error,
    refetch,
    ...rest
  } = useQuery(getAccommodationReservationSettingsQuery, {
    variables: {
      accommodationId,
    },
    ...useQueryOptions,
  });

  return [
    destructSingleField(reservationSettingData).reservationSettings,
    {
      loading,
      error,
      refetch,
      ...rest,
    },
  ];
};

export const usePublishAccommodationNotification = () => {
  const { accommodationId } = useParams();
  const [emitPublishNotification] = useMutation(
    publishAccommodationNotificaitonMutation,
  );

  const publishAccommodationNotification = useCallback(
    ({
      type,
      priority,
      data,
      objective,
      relative,
      description,
      roomTypeId,
      template,
    }) =>
      emitPublishNotification({
        variables: {
          input: {
            accommodationId,
            type,
            priority,
            data,
            objective,
            relative,
            description,
            roomTypeId,
            template,
          },
        },
      }),
    [emitPublishNotification],
  );

  return publishAccommodationNotification;
};

export const usePermanentState = (defaultState, permanentKey) => {
  const [permanentStateSubscription, setPermanentStateSubscription] =
    useRecoilState(permanentStateAtom);
  const [latestSubscriptionTimestamp, setLatestSubscriptionTimestamp] =
    useState(null);
  const [permanentValue, _setPermanentValue] = useState(
    localStorage.getItem(permanentKey) || defaultState,
  );

  useEffect(() => {
    if (
      permanentStateSubscription[permanentKey] &&
      latestSubscriptionTimestamp !== permanentStateSubscription[permanentKey]
    ) {
      setLatestSubscriptionTimestamp(permanentStateSubscription[permanentKey]);
      _setPermanentValue(localStorage.getItem(permanentKey));
    }
  }, [permanentStateSubscription, latestSubscriptionTimestamp]);

  const setPermanentValue = (newValue) => {
    _setPermanentValue(newValue);
    setPermanentStateSubscription({
      ...permanentStateSubscription,
      [permanentKey]: new Date().getTime(),
    });

    if (newValue) {
      localStorage.setItem(permanentKey, newValue);
    } else {
      localStorage.removeItem(permanentKey);
    }
  };

  return [permanentValue, setPermanentValue];
};

export const useAccommodationOtaList = ({ excludeDefault } = {}) => {
  const { accommodationId } = useParams();

  const { data: otaListData } = useQuery(getAccommodationOtaListQuery, {
    variables: {
      accommodationId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const otaList = useMemo(
    () =>
      excludeDefault
        ? destructSingleField(otaListData, [])
        : [
            ...destructSingleField(otaListData, []),
            { key: 'MANUAL', name: '수기예약' },
            { key: 'KIOSK', name: '키오스크' },
          ],
    [otaListData, excludeDefault],
  );

  return otaList;
};

export const useAccommodationKioskOptions = () => {
  const { accommodationId } = useParams();
  const { data } = useQuery(getAccommodationKiosksOptionsQuery, {
    variables: {
      id: accommodationId,
    },
  });

  const accommodation = destructSingleField(data);

  return accommodation?.kiosks || [];
};

export const useSortAndGroupRooms = ({
  rooms,
  sortBy,
  sortDirection,
  showNFS,
  groupByWing,
  showTemporal,
}) => {
  const sortAndGroupRooms = useCallback(
    (targetRooms) => {
      if (!targetRooms) return [];

      let filteredRooms = showNFS
        ? targetRooms
        : targetRooms.filter((room) => room.saleState !== 'DISABLED');

      if (!showTemporal) {
        filteredRooms = filteredRooms.filter(
          (room) =>
            !(
              /임시|예비|^.$/.test(room.name) ||
              /임시|예비|^.$/.test(room.roomType?.name)
            ),
        );
      }

      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
      });
      const sortRooms = (sortTargetRooms, dir) =>
        sortTargetRooms.sort((prev, next) =>
          (dir || sortDirection) === 'asc'
            ? collator.compare(prev.name, next.name)
            : collator.compare(next.name, prev.name),
        );

      let resultRooms;

      switch (sortBy) {
        case 'all': {
          resultRooms = [sortRooms(filteredRooms.slice())];
          break;
        }
        case 'floor': {
          resultRooms = Object.entries(
            filteredRooms.reduce(
              (acc, room) => ({
                ...acc,
                [extractFloor(room.name)]: [
                  ...(acc[extractFloor(room.name)] || []),
                  room,
                ],
              }),
              {},
            ),
          )
            .sort((prev, next) =>
              sortDirection === 'asc'
                ? collator.compare(prev[0], next[0])
                : collator.compare(next[0], prev[0]),
            )
            .map(([, floorRooms]) => sortRooms(floorRooms, 'asc'));
          break;
        }
        case 'type': {
          resultRooms = Object.values(
            filteredRooms.reduce(
              (acc, room) => ({
                ...acc,
                [room.roomType.name]: [
                  ...(acc[room.roomType.name] || []),
                  room,
                ],
              }),
              {},
            ),
          )
            .sort(
              (next, prev) =>
                next[0].roomType.priority - prev[0].roomType.priority,
            )
            .map((typeRooms) => sortRooms(typeRooms));
          break;
        }
        default: {
          resultRooms = [filteredRooms];
          break;
        }
      }

      return resultRooms.filter((roomGroup) => roomGroup.length);
    },
    [showNFS, showTemporal, sortBy, sortDirection],
  );

  const roomGroups = useMemo(() => {
    if (!groupByWing) {
      return sortAndGroupRooms(rooms);
    }

    const wingNameRoomsMap = rooms.reduce((acc, cur) => {
      const wing = parseWingFromRoomName(cur.name);

      return { ...acc, [wing]: [...(acc[wing] || []), cur] };
    }, {});

    return Object.keys(wingNameRoomsMap)
      .sort()
      .map((wingName) => {
        const wingRooms = wingNameRoomsMap[wingName];
        const groupedWingRooms = sortAndGroupRooms(wingRooms);

        return { wing: wingName, rooms: groupedWingRooms };
      })
      .filter((wing) => wing.rooms.length);
  }, [rooms, sortAndGroupRooms, groupByWing]);

  const roomTypeRenderCountMap = useMemo(() => {
    if (groupByWing && sortBy === 'type' && roomGroups) {
      const renderCountMap = {};

      roomGroups.forEach((wingGroup) => {
        wingGroup.rooms.forEach((roomBlock) => {
          let renderCount = renderCountMap[roomBlock[0].roomTypeId];

          if (!renderCount) {
            renderCount = { total: 0, max: 0 };
            renderCountMap[roomBlock[0].roomTypeId] = renderCount;
          }

          renderCount.total += roomBlock.length;
          renderCount.max = Math.max(renderCount.max, roomBlock.length);
        });
      });

      return Object.entries(renderCountMap)
        .map(([roomTypeId, counts]) => ({ ...counts, roomTypeId }))
        .sort((prev, next) => next.total - prev.total);
    }

    return null;
  }, [roomGroups, sortBy, groupByWing]);

  return [roomGroups, groupByWing, { renderIndexes: roomTypeRenderCountMap }];
};

export const useReservationPackages = () => {
  const { accommodationId } = useParams();
  const {
    data: packagesData,
    loading,
    error,
  } = useQuery(getAccommodationPackagesQuery, {
    variables: {
      accommodationId,
    },
  });
  const reservationPackages = useMemo(
    () => destructSingleField(packagesData, []),
    [packagesData],
  );

  return [reservationPackages, { loading, error }];
};

export const useOtaPriceContractions = (
  { accommodationOtaId, roomTypeId, reservationPackageId } = {},
  queryOptions,
) => {
  const { data, loading, error } = useQuery(getOtaPriceContractionsQuery, {
    variables: { accommodationOtaId, roomTypeId, reservationPackageId },
    ...queryOptions,
  });
  const otaPriceContractions = useMemo(
    () =>
      destructSingleField(data, [])
        .slice()
        .sort(
          (prev, next) =>
            prev.roomTypeId?.localeCompare(next.roomTypeId) ||
            prev.reservationPackageId?.localeCompare(next.reservationPackageId),
        ),
    [data],
  );
  return [otaPriceContractions, { data, loading, error }];
};

export const useRefineHistoryLog = () => {
  const rooms = useAccommodationRooms();
  const [firstKiosk] = useAccommodationKioskOptions();
  const { firstCashDispensorAmount, secondCashDispensorAmount } =
    firstKiosk?.options || {};
  const roomIdDataMap = useMemo(
    () => rooms?.reduce((acc, room) => ({ ...acc, [room.id]: room }), {}) || {},
    [rooms],
  );
  const refineHistoryLog = useCallback(
    (history) => {
      if (roomIdDataMap[history.objective]) {
        return { ...history, objective: roomIdDataMap[history.objective].name };
      }
      if (history.template === 'FIRST_BILL_DISPENSER_KIOSKERROR') {
        return { ...history, objective: firstCashDispensorAmount };
      }
      if (history.template === 'SECOND_BILL_DISPENSER_KIOSKERROR') {
        return { ...history, objective: secondCashDispensorAmount };
      }
      return history;
    },
    [roomIdDataMap, firstKiosk],
  );

  return refineHistoryLog;
};

export const useOnClickOutside = ({ onClickOutside, eventType }) => {
  const wrapperRef = useRef();

  useEffect(() => {
    const refinedClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickOutside(event);
      }
    };

    document.addEventListener(eventType || 'mouseup', refinedClickOutside);

    return () =>
      document.removeEventListener(eventType || 'mouseup', refinedClickOutside);
  }, [onClickOutside]);

  return { wrapperRef };
};

const INIT_AGG_ITEM = () => ({
  amount: 0,
  outstandingAmount: 0,
  count: 0,
});

export const usePaymentAggregations = ({ from, to, dateFilterType }) => {
  const { accommodationId } = useParams();

  const { data, loading } = useQuery(aggregatePaymentsQuery, {
    variables: {
      accommodationId,
      from,
      to,
      dateType: dateFilterType,
    },
  });

  return useMemo(() => {
    const calcKioskAggregations = {
      rent: {
        cash: INIT_AGG_ITEM(),
        creditCard: INIT_AGG_ITEM(),
        etc: INIT_AGG_ITEM(),
        total: INIT_AGG_ITEM(),
      },
      lodge: {
        cash: INIT_AGG_ITEM(),
        creditCard: INIT_AGG_ITEM(),
        etc: INIT_AGG_ITEM(),
        total: INIT_AGG_ITEM(),
      },
    };

    const calcManualAggregations = {
      rent: {
        cash: INIT_AGG_ITEM(),
        creditCard: INIT_AGG_ITEM(),
        etc: INIT_AGG_ITEM(),
        total: INIT_AGG_ITEM(),
      },
      lodge: {
        cash: INIT_AGG_ITEM(),
        creditCard: INIT_AGG_ITEM(),
        etc: INIT_AGG_ITEM(),
        total: INIT_AGG_ITEM(),
      },
    };

    const calcPlatformAggregations = {
      rent: {
        platform: INIT_AGG_ITEM(),
        etc: INIT_AGG_ITEM(),
        total: INIT_AGG_ITEM(),
      },
      lodge: {
        platform: INIT_AGG_ITEM(),
        etc: INIT_AGG_ITEM(),
        total: INIT_AGG_ITEM(),
      },
    };

    const calcTotalAggregations = {
      rent: {
        cash: INIT_AGG_ITEM(),
        creditCard: INIT_AGG_ITEM(),
        etc: INIT_AGG_ITEM(),
        total: INIT_AGG_ITEM(),
      },
      lodge: {
        cash: INIT_AGG_ITEM(),
        creditCard: INIT_AGG_ITEM(),
        etc: INIT_AGG_ITEM(),
        total: INIT_AGG_ITEM(),
      },
    };

    const gatherAggregation = (targetAggregations, operandAggregation) => {
      let normalizedType = operandAggregation.type;
      let normalizedReservationType = operandAggregation.reservationType;
      if (operandAggregation.type === 'card') {
        normalizedType = 'creditCard';
      }
      if (!['rent', 'lodge'].includes(normalizedReservationType)) {
        normalizedReservationType = 'lodge';
      }
      const aggregationItem =
        targetAggregations[normalizedReservationType][normalizedType] ||
        targetAggregations[normalizedReservationType].etc;
      const typeTotalItem = targetAggregations[normalizedReservationType].total;
      aggregationItem.amount += operandAggregation.amount;
      aggregationItem.outstandingAmount += operandAggregation.outstandingAmount;
      aggregationItem.count += operandAggregation.count;
      typeTotalItem.amount += operandAggregation.amount;
      typeTotalItem.outstandingAmount += operandAggregation.outstandingAmount;
      typeTotalItem.count += operandAggregation.count;
    };

    if (data) {
      const aggregations = destructSingleField(data);

      aggregations.forEach((aggregation) => {
        if (aggregation.createdBy === 'kiosk') {
          try {
            gatherAggregation(calcKioskAggregations, aggregation);
          } catch (err) {
            console.log(err);
          }
        } else if (aggregation.createdBy === 'manual') {
          try {
            gatherAggregation(calcManualAggregations, aggregation);
          } catch (err) {
            console.log(err);
          }
        } else if (
          aggregation.createdBy === 'auto' ||
          aggregation.createdBy === 'bookingEngine'
        ) {
          try {
            gatherAggregation(calcPlatformAggregations, aggregation);
          } catch (err) {
            console.log(err);
          }
        }
        try {
          gatherAggregation(calcTotalAggregations, aggregation);
        } catch (err) {
          console.log(err);
        }
      });
    }

    return {
      kioskAggregations: calcKioskAggregations,
      manualAggregations: calcManualAggregations,
      platformAggregations: calcPlatformAggregations,
      totalAggregations: calcTotalAggregations,
      loading,
    };
  }, [data]);
};

export const useGuestReservationQuery = () => {
  const { reservationId } = useParams();
  const [sessionToken, setSessionToken] = usePermanentState(
    null,
    `${reservationId}_AUTH_TOKEN`,
  );

  const { data, loading, error } = useQuery(
    getSingleReservationByGuestSessionQuery,
    {
      variables: {
        reservationId,
        sessionToken,
      },
    },
  );

  const reservation = useMemo(() => destructSingleField(data), [data]);

  return {
    reservation,
    data,
    loading,
    error,
    reservationId,
    sessionToken,
    setSessionToken,
  };
};

export const useReservationSettingsByGuestSession = () => {
  const { reservationId } = useParams();
  const [sessionToken] = usePermanentState(null, `${reservationId}_AUTH_TOKEN`);

  const {
    data: reservationSettingData,
    loading,
    error,
    refetch,
    ...rest
  } = useQuery(getReservationSettingByGuestSessionQuery, {
    variables: {
      reservationId,
      sessionToken,
    },
  });

  return [
    reservationSettingData?.getReservationSettingByGuestSession,
    {
      loading,
      error,
      refetch,
      ...rest,
    },
  ];
};

export const useConciergeServiceSetting = () => {
  const { reservationId } = useParams();
  const [accommodationId] = usePermanentState(
    null,
    `${reservationId}_ACCOMMODATION_TOKEN`,
  );
  const [sessionToken] = usePermanentState(null, `${reservationId}_AUTH_TOKEN`);

  const {
    data: conciergeServiceSetting,
    loading,
    error,
    refetch,
    ...rest
  } = useQuery(getConciergeServiceSettingByGuestSessionQuery, {
    variables: {
      input: {
        reservationId,
        sessionToken,
        accommodationId,
      },
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });

  return [
    conciergeServiceSetting?.getConciergeServiceSettingByGuestSession,
    {
      loading,
      error,
      refetch,
      ...rest,
    },
  ];
};

export const useOpenDropdown = (initailValue) => {
  const [isOpen, setIsOpen] = useState(initailValue || false);
  const onOpened = useCallback((value) => {
    setIsOpen(value);
  }, []);

  return [isOpen, onOpened];
};

export const usePagination = () => {
  const [pagination, setPagination] = React.useState({
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
    totalPages: 0,
    last: false,
    first: false,
    page: 1,
    limit: 6,
  });

  const onUpdate = React.useCallback((updatedPagination) => {
    setPagination((initialPagination) => ({
      ...initialPagination,
      ...updatedPagination,
    }));
  }, []);

  return { pagination, setPagination: onUpdate };
};

export const usePaymentOption = () => {
  const [conciergeSetting] = useConciergeServiceSetting();
  const [paymentOptions, setPaymentOptions] = React.useState([]);

  const initialLoad = () => {
    if (conciergeSetting) {
      const newOptions = [];

      const { onSitePaymentByCard, onSitePaymentByCash } = conciergeSetting;

      if (onSitePaymentByCard) {
        newOptions.push({
          rule: 'DIRECT_CARD',
          name: '신용/체크카드',
          icon: 'card',
        });
      }

      if (onSitePaymentByCash) {
        newOptions.push({
          rule: 'DIRECT_CASH',
          name: '현금결제',
          icon: 'cash',
        });
      }

      setPaymentOptions(newOptions);
    }
  };

  React.useEffect(() => {
    initialLoad();
  }, [conciergeSetting]);

  return { paymentOptions };
};

export const useConciergeWorkSetting = () => {
  const { reservationId } = useParams();

  const [saveAccommodationId] = usePermanentState(
    null,
    `${reservationId}_ACCOMMODATION_TOKEN`,
  );
  const { data } = useQuery(getConciergeWorkSettingsQuery, {
    variables: { accommodationId: saveAccommodationId },
    pollInterval: 2500,
    fetchPolicy: 'network-only',
  });

  return destructSingleField(data, []);
};

function refineWeekDayToString(weekDay: number) {
  switch (weekDay) {
    case 0:
      return 'sun';
    case 1:
      return 'mon';
    case 2:
      return 'tue';
    case 3:
      return 'wed';
    case 4:
      return 'thu';
    case 5:
      return 'fri';
    case 6:
      return 'sun';
    default:
      return '';
  }
}

export const useProductStatusInConcierge = (props) => {
  const [conciergeSetting] = useConciergeServiceSetting();
  const workSettings = useConciergeWorkSetting();
  const { isRestTime, breakEndTime } = useMemo(() => {
    const restTimes =
      workSettings.filter(
        (work) =>
          work?.weekDay ===
            refineWeekDayToString(moment().subtract(8, 'hours').weekday()) &&
          /^REST/.test(work.workType),
      ) || [];
    let _breakEndTime;
    const checkedRestTimeValues = restTimes
      ?.reduce((acc, cur) => {
        const [startTime, endTime] = [
          cur.value.slice(0, 4),
          cur.value.slice(4),
        ];
        const now = moment();
        if (startTime < endTime) {
          if (
            now.isSameOrAfter(moment(startTime, 'HHmm')) &&
            now.isSameOrBefore(moment(endTime, 'HHmm'))
          ) {
            _breakEndTime = moment(endTime, 'HHmm');
            acc.push(true);
            return acc;
          }
          acc.push(false);
          return acc;
        }
        if (
          now.isSameOrAter(
            moment(startTime, 'HHmm').subtract(now.hour() > 0 ? 1 : 0, 'day'),
          ) &&
          now.isSameOrBefore(
            moment(endTime, 'HHmm').add(now.hour > 8 ? 1 : 0, 'day'),
          )
        ) {
          acc.push(true);
          _breakEndTime = moment(endTime, 'HHmm').add(
            now.hour > 8 ? 1 : 0,
            'day',
          );
          return acc;
        }
        acc.push(false);
        _breakEndTime = null;
        return acc;
      }, [])
      .filter(Boolean);
    return {
      isRestTime: checkedRestTimeValues.length > 0 && restTimes.length > 0,
      breakEndTime: _breakEndTime,
    };
  }, [workSettings]);
  const isClosed = useMemo(() => {
    const workTimes =
      workSettings.filter(
        (work) =>
          work?.weekDay ===
            refineWeekDayToString(moment().subtract(8, 'hours').weekday()) &&
          /^WORK/.test(work.workType),
      ) || [];
    if (!workTimes?.[0]?.value) {
      return true;
    }
    if (workTimes?.[0]?.value === '00000000') {
      return false;
    }
    const [startTime, endTime] = [
      workTimes?.[0]?.value?.slice(0, 4) || '0000',
      workTimes?.[0]?.value?.slice(4) || '0000',
    ];
    const nowTime = moment();
    const nowSaleDate = moment().subtract(8, 'h').format('YYYYMMDD');
    let startDate = moment(`${nowSaleDate}${startTime}`, 'YYYYMMDDHHmm');
    let endDate = moment(`${nowSaleDate}${endTime}`, 'YYYYMMDDHHmm');
    if (startTime > endTime) {
      startDate = moment(`${nowSaleDate}${startTime}`, 'YYYYMMDDHHmm');
      endDate = moment(`${nowSaleDate}${endTime}`, 'YYYYMMDDHHmm').add(1, 'd');
    }
    return nowTime.isAfter(endDate) || nowTime.isBefore(startDate);
  }, [workSettings]);
  const nextWorkTime = useMemo(() => {
    const nowDate = moment().format('YYYYMMDD');
    if (isClosed) {
      const todayOpenDate = moment(
        `${nowDate}${
          (workSettings.filter(
            (work) =>
              work?.weekDay ===
                refineWeekDayToString(
                  moment().subtract(8, 'hours').weekday(),
                ) && /^WORK/.test(work.workType),
          ) || [])?.[0]?.value?.slice(0, 4) || '0000'
        }`,
        'YYYYMMDDHHmm',
      );
      const isBeforeOpen = todayOpenDate.isAfter(moment());
      if (isBeforeOpen) {
        return todayOpenDate;
      }
    }
    const workTimes =
      workSettings.filter(
        (work) =>
          work?.weekDay ===
            refineWeekDayToString(
              moment().add(1, 'day').subtract(8, 'hours').weekday(),
            ) && /^WORK/.test(work.workType),
      ) || [];
    return workTimes?.[0]?.value === '00000000'
      ? moment(`${nowDate}0800`, 'YYYYMMDDHHmm')
      : moment(
          `${nowDate}${workTimes?.[0]?.value?.slice(0, 4) || '0800'}`,
          'YYYYMMDDHHmm',
        );
  }, [workSettings]);

  console.log(nextWorkTime);
  const isTempWorkingStop = moment(conciergeSetting?.tempWorkingStop).isAfter(
    moment(),
  );

  const isRoomServiceActivated =
    conciergeSetting?.roomServiceActivated &&
    !moment(conciergeSetting?.roomServiceActivated).isAfter(moment());
  const isAmenityActivated =
    conciergeSetting?.amenityActivated &&
    !moment(conciergeSetting?.amenityActivated).isAfter(moment());

  const isSoldOut =
    props?.data?.soldOutPeriod &&
    moment(props.data.soldOutPeriod).isAfter(moment());
  const result = {
    isRoomServiceDisabled:
      isClosed ||
      isTempWorkingStop ||
      isRestTime ||
      !isRoomServiceActivated ||
      isSoldOut,
    isAmenityDisabled:
      isClosed ||
      isTempWorkingStop ||
      isRestTime ||
      !isAmenityActivated ||
      isSoldOut,
    tempWorkingStop: moment(conciergeSetting?.tempWorkingStop),
    isTempWorkingStop,
    isRoomServiceActivated,
    isAmenityActivated,
    isRestTime,
    breakEndTime,
    isClosed,
    nextWorkTime,
  };

  console.log(result);
  return result;
};
