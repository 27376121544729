import jwt from 'jsonwebtoken';
import { createApolloFetch } from 'apollo-fetch';
import { CONSTANTS, API_HOST } from './constants';

const fetch = createApolloFetch({
  uri: `${API_HOST}/graphql`,
});

class AuthHandler {
  constructor() {
    const savedAccessToken = localStorage.getItem(CONSTANTS.ACCESS_TOKEN);

    if (savedAccessToken) {
      this.selectedStorage = localStorage;
      this.user = jwt.decode(savedAccessToken);
    } else {
      const savedSessionStorageToken = sessionStorage.getItem(
        CONSTANTS.ACCESS_TOKEN,
      );
      if (savedSessionStorageToken) {
        this.selectedStorage = sessionStorage;
        this.user = jwt.decode(savedSessionStorageToken);
      }
    }
  }

  get refreshToken() {
    if (!this.selectedStorage) return null;
    return this.selectedStorage.getItem(CONSTANTS.REFRESH_TOKEN);
  }

  get accessToken() {
    if (!this.selectedStorage) return null;
    return this.selectedStorage.getItem(CONSTANTS.ACCESS_TOKEN);
  }

  openOtpPopup = () => window.requestOTPAuthenticate?.();

  validateAccessToken = async () => {};

  signIn = (accessToken, refreshToken, usePermenant) => {
    this.__sessionCache__signedIn = true;
    this.user = jwt.decode(accessToken);
    this.selectedStorage = (usePermenant && localStorage) || sessionStorage;
    this.selectedStorage.setItem(CONSTANTS.ACCESS_TOKEN, accessToken);

    if (refreshToken) {
      this.selectedStorage.setItem(CONSTANTS.REFRESH_TOKEN, refreshToken);
    }
  };

  signOut = () => {
    this.selectedStorage.removeItem(CONSTANTS.ACCESS_TOKEN);
    this.selectedStorage.removeItem(CONSTANTS.REFRESH_TOKEN);
    this.user = null;
    window.location.href = '/signin';
    window.location.reload();
  };

  refreshAccessToken = async () => {
    try {
      const refreshResult = await fetch({
        query: `
          query($refreshToken: String!) {
            refreshToken(refreshToken: $refreshToken) {
              accessToken
              refreshToken
            }
          }
        `,
        variables: {
          refreshToken: this.refreshToken,
        },
      });

      const {
        data: {
          refreshToken: { accessToken, refreshToken },
        },
      } = refreshResult;

      this.selectedStorage.setItem(CONSTANTS.ACCESS_TOKEN, accessToken);
      this.selectedStorage.setItem(CONSTANTS.REFRESH_TOKEN, refreshToken);

      return accessToken;
    } catch (error) {
      console.log(error);

      if (this.accessToken) {
        this.signOut();
      }
    }

    return true;
  };
}

const authHandler = new AuthHandler();

window.authHandler = authHandler;
window.CONSTANTS = CONSTANTS;

export default authHandler;
