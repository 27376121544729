import { gql } from '@apollo/client';
import { PaymentFragment } from 'graphql/payment';
import { connectionTemplate } from '.';

export const SimpleReservationFragment = gql`
  fragment SimpleReservationFragment on Reservation {
    id
    type
    intendedType
    useStartAt
    useExpireAt
    number
    isPaid
    useVehicle
    vehicleNumber
    platform
    phone
    guestName
    paymentId
    roomTypeId
    isCancelled
    createdAt
    createdBy
  }
`;

export const ReservationGroupFragment = gql`
  fragment ReservationGroupFragment on ReservationGroup {
    id
    accommodationId
    groupName
    useStartAt
    useExpireAt
    platform
    status
    reservedBy
    companyAssigneeId
    assignee {
      id
      name
    }
    phone
    email
    memo
  }
`;

export const ReservationFragment = gql`
  fragment ReservationFragment on Reservation {
    id
    type
    intendedType
    isLongTerm
    useStartAt
    useExpireAt
    intendedUseStartAt
    intendedUseExpireAt
    number
    sleeps
    otaNumber
    isPaid
    status
    useVehicle
    vehicleNumber
    platform
    phone
    guestName
    paymentId
    createdAt
    createdBy
    data
    isCancelled
    memo {
      id
      content
    }
    roomId
    room {
      id
      name
    }
    roomType {
      id
      alias
      name
    }
    roomTypeId
    reservationGroup {
      ...ReservationGroupFragment
    }
  }

  ${ReservationGroupFragment}
`;

export const ReservationFragmentWithoutRoom = gql`
  fragment ReservationFragmentWithoutRoom on Reservation {
    id
    type
    intendedType
    isLongTerm
    useStartAt
    useExpireAt
    intendedUseStartAt
    intendedUseExpireAt
    number
    isPaid
    sleeps
    status
    useVehicle
    vehicleNumber
    platform
    phone
    guestName
    paymentId
    createdAt
    createdBy
    isCancelled
    data
    memo {
      id
      content
    }
  }
`;

export const changeReservationRoomMutation = gql`
  mutation changeReservationRoom($input: ChangeReservationRoomInput!) {
    changeReservationRoom(input: $input) {
      ...ReservationFragment
    }
  }

  ${ReservationFragment}
`;

export const reservationCheckInMutation = gql`
  mutation reservationCheckIn($reservationId: ID!) {
    reservationCheckIn(reservationId: $reservationId) {
      ...ReservationFragment
    }
  }

  ${ReservationFragment}
`;

export const reservationCheckOutMutation = gql`
  mutation reservationCheckOut($reservationId: ID!) {
    reservationCheckOut(reservationId: $reservationId) {
      ...ReservationFragment
    }
  }

  ${ReservationFragment}
`;

export const cancelAssignReservationMutation = gql`
  mutation cancelAssignReservation($reservationId: ID!) {
    cancelAssignReservation(reservationId: $reservationId) {
      ...ReservationFragment
    }
  }

  ${ReservationFragment}
`;

export const getSingleReservationPaymentQuery = gql`
  query getSingleReservation($reservationId: ID!) {
    getSingleReservation(reservationId: $reservationId) {
      id
      room {
        id
        name
      }
      payment {
        ...PaymentFragment
      }
    }
  }
  ${PaymentFragment}
`;

export const getAccommodationReservationsQuery = gql`
query getAccommodationReservations($accommodationId: ID!, $first: Int, $after: String, $filter: ReservationQueryFilter) @connection(key: "accommodationReservation", filter: ["accommodationId", "filter"]) {
  getAccommodationReservations(accommodationId: $accommodationId, filter: $filter, first: $first, after: $after) {
    ${connectionTemplate`
      ...ReservationFragment
    `}
  }
}
${ReservationFragment}
`;

export const getAccommodationReservationsWithPaymentsQuery = gql`
query getAccommodationReservations($accommodationId: ID!, $first: Int, $after: String, $filter: ReservationQueryFilter) @connection(key: "accommodationReservation", filter: ["accommodationId", "filter"]) {
  getAccommodationReservations(accommodationId: $accommodationId, filter: $filter, first: $first, after: $after) {
    ${connectionTemplate`
      ...ReservationFragment
      payment {
        id
        type
        amount
        useStartAt
        totalAmount
        isFullyPaid
        isCancelled
        childPayments {
          id
          type
          amount
          useStartAt
          isFullyPaid
          isCancelled
        }
      }
    `}
  }
}
${ReservationFragment}
`;

export const getAccommodationDailyQuantity = gql`
  query ($accommodationId: ID!, $startDate: String!) {
    getSingleAccommodation(id: $accommodationId) {
      id
      name
      roomTypes {
        id
        name
        dailyQuantities(startDate: $startDate) {
          id
          date
          quantity
        }
      }
    }
  }
`;

export const createOrUpdateDailyQuantityMutation = gql`
  mutation ($input: DailyQuantityInput!) {
    createOrUpdateDailyQuantity(input: $input) {
      id
      roomTypeId
      quantity
      date
    }
  }
`;

export const getAccommodationUpcomingReservationsQuery = gql`
  query getAccommodationUpcomingReservations($id: ID!) {
    getSingleAccommodation(id: $id) {
      id
      upcomingReservations {
        ...ReservationFragment
      }
    }
  }

  ${ReservationFragment}
`;

export const getSinglePaymentReservationQuery = gql`
  query getSinglePayment($paymentId: ID!) {
    getSinglePayment(paymentId: $paymentId) {
      id
      reservation {
        ...ReservationFragment
        roomType {
          id
          alias
          name
        }
      }
    }
  }
  ${ReservationFragment}
`;

export const assignRoomToReservationMutation = gql`
  mutation ($input: AssignReservationInput!) {
    assignRoomToReservation(input: $input) {
      id
      roomId
      status
    }
  }
`;

export const addReservationMutation = gql`
  mutation addReservation(
    $input: AddReservationInput!
    $needCheckIn: Boolean
    $sendSMS: Boolean
  ) {
    addReservation(
      input: $input
      needCheckIn: $needCheckIn
      sendSMS: $sendSMS
    ) {
      ...ReservationFragment
    }
  }
  ${ReservationFragment}
`;

export const modifyReservationMutation = gql`
  mutation modifyReservation($input: ModifyReservationInput!) {
    modifyReservation(input: $input) {
      ...ReservationFragment
      payment {
        ...PaymentFragment
      }
    }
  }
  ${ReservationFragment}
  ${PaymentFragment}
`;

export const deleteReservationMutation = gql`
  mutation deleteReservation($id: ID!) {
    deleteReservation(id: $id) {
      ...ReservationFragment
    }
  }
  ${ReservationFragment}
`;

export const addReservationGroupMutation = gql`
  mutation addReservationGroup($input: AddReservationGroupInput!) {
    addReservationGroup(input: $input) {
      ...ReservationGroupFragment
    }
  }
  ${ReservationGroupFragment}
`;

export const modifyReservationGroupMutation = gql`
  mutation modifyReservationGroup($input: ModifyReservationGroupInput!) {
    modifyReservationGroup(input: $input) {
      ...ReservationGroupFragment
    }
  }
  ${ReservationGroupFragment}
`;

export const deleteReservationGroupMutation = gql`
  mutation deleteReservationGroup($id: ID!) {
    deleteReservationGroup(id: $id)
  }
`;

export const getReservationSettingByGuestSessionQuery = gql`
  query getReservationSettingByGuestSession(
    $reservationId: ID!
    $sessionToken: String!
  ) {
    getReservationSettingByGuestSession(
      reservationId: $reservationId
      sessionToken: $sessionToken
    ) {
      addonTimeUnit
    }
  }
`;

export const getAccommodationReservationSettingsQuery = gql`
  query getAccommodationReservationSettings($accommodationId: ID!) {
    getSingleAccommodation(id: $accommodationId) {
      id
      reservationSettings {
        roomAssignOrderBy
        maxParking
        preserveParking
        assignRoomBefore
        logSuspicious
        defaultCheckedIn
        unlimitedCheckIn
        unlimitedRentCheckIn
        unlimitedRentCheckInDays
        assumeCheckoutBefore
        ignoreFutureOtaIntegration
        dailyCheckOutTime
        reservationCreatedTemplate
        checkInTemplate
        reservationCancelledTemplate
        useAssignRoom
        reservationTableColumns
        useManualAssignAutoAdjustment
        parsedOTADefaultCheckIn
        parsedOTADefaultCheckOut
        addonTimeUnit
      }
    }
  }
`;

export const setReservationSettingsMutation = gql`
  mutation setReservationSettings(
    $inputs: [ReservationSettingInput]!
    $accommodationId: ID!
  ) {
    setReservationSettings(inputs: $inputs, accommodationId: $accommodationId) {
      key
      value
    }
  }
`;

export const dateQuantitiesQuery = gql`
  query getSingleAccommodation($id: ID!, $startDate: Date!, $endDate: Date!) {
    getSingleAccommodation(id: $id) {
      id
      roomTypes {
        id
        name
        quantity
        dayQuantities
        dateQuantities(startDate: $startDate, endDate: $endDate) {
          date
          quantity
        }
      }
    }
  }
`;

export const getSingleReservationGroupQuery = gql`
  query getSingleReservationGroup($reservationGroupId: ID!) {
    getSingleReservationGroup(reservationGroupId: $reservationGroupId) {
      ...ReservationGroupFragment

      reservations {
        ...ReservationFragment
        payment {
          ...PaymentFragment
        }
      }
    }
  }

  ${ReservationGroupFragment}
  ${ReservationFragment}
  ${PaymentFragment}
`;

export const datePricesQuery = gql`
  query getSingleAccommodation($id: ID!, $startDate: Date!, $endDate: Date!) {
    getSingleAccommodation(id: $id) {
      id
      roomTypes {
        id
        name
        datePrices(startDate: $startDate, endDate: $endDate) {
          date
          price {
            id
            cardAmount
            bookingOriginAmount
            bookingActualAmount
            extraGuestAmount
          }
        }
      }
    }
  }
`;

export const getReservationsInDateQuery = gql`
  query getReservationsInDate(
    $accommodationId: ID!
    $startDate: Date!
    $endDate: Date!
  ) {
    getLodgeReservationsInDate(
      accommodationId: $accommodationId
      startDate: $startDate
      endDate: $endDate
    ) {
      ...SimpleReservationFragment
    }
    getRentReservationsInDate(
      accommodationId: $accommodationId
      startDate: $startDate
      endDate: $endDate
    ) {
      ...SimpleReservationFragment
    }
  }

  ${SimpleReservationFragment}
`;

export const resendSmsForReservationMutation = gql`
  mutation resendSmsForReservation(
    $id: ID!
    $type: String
    $roomName: String
    $roomTypeName: String
  ) {
    resendSmsForReservation(
      id: $id
      type: $type
      roomName: $roomName
      roomTypeName: $roomTypeName
    )
  }
`;

export const preregisterParkingByGuestSessionMutation = gql`
  mutation preregisterParkingByGuestSession(
    $input: PreregisterParkingByGuestSessionInput!
  ) {
    preregisterParkingByGuestSession(input: $input)
  }
`;
