import { ConfirmationDialog } from 'components/ui/Modal';
import React, { useState } from 'react';

const NeedConfirmContext = React.createContext({
  needConfirm: false,
  needConfirmDialog: false,
  setNeedConfirm: () => {},
  setConfirmDialog: () => {},
});

export default NeedConfirmContext;

const { Provider } = NeedConfirmContext;

const NeedConfirmProvider = ({ children }: { children: React.ReactChildren }) => {
  const [state, setState] = useState({
    needConfirm: false,
    confirmDialog: false,
    setNeedConfirm: (needConfirm) => {
      setState({ ...state, needConfirm });
    },
    setConfirmDialog: (confirmDialogProps) => {
      setState({ ...state, confirmDialog: confirmDialogProps });
    },
  });

  return (
    <Provider value={state}>
      <>
        {
          children
        }
        {
          state.confirmDialog && <ConfirmationDialog {...state.confirmDialog} />
        }
      </>
    </Provider>
  );
};

export {
  NeedConfirmProvider,
};
