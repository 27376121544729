import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Portal } from '@material-ui/core';
import STYLED_MACROS from 'styles/styledMacros';
import withCloseOnPopState from 'hocs/withCloseOnPopState';

const GlobalStyle = createGlobalStyle`
  #appLayout {
    transform: translate3d(-100%, 0, 0);
    -webkit-transform: translate3d(-100%, 0, 0);
  }
`;

const PopupPage = styled.div`
  background: ${STYLED_MACROS.BACKGROUND.LIGHT};
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  transform: translate3d(${(props) => (props.open && '0') || '100%'}, 0, 0);
  -webkit-transform: translate3d(${(props) => (props.open && '0') || '100%'}, 0, 0);
  transition: all 0.2s;
`;

const PopupContent = styled.div`
  flex-grow: 1;
  min-height: 0;
  flex-shrink: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const PopupPageContainer = ({
  open,
  onClose,
  noShrink,
  children,
  ...restProps
}) => {
  const root = document.getElementById('root');

  return (
    <>
      {open && <GlobalStyle />}
      <Portal container={root}>
        <PopupPage {...restProps} open={open}>
          <PopupContent style={{ flexShrink: noShrink ? 0 : 1 }}>
            {children}
          </PopupContent>
        </PopupPage>
      </Portal>
    </>
  );
};

export const MountPopup = ({ mount, children }) => {
  const [innerMount, setInnerMount] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);

  useEffect(() => {
    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }
    if (mount && mount !== innerMount) {
      const newTimeout = setTimeout(() => {
        setInnerMount(true);
      }, 10);
      setCurrentTimeout(newTimeout);
    }

    if (!mount && innerMount) {
      const newTimeout = setTimeout(() => {
        setInnerMount(false);
      }, 500);
      setCurrentTimeout(newTimeout);
    }
  }, [mount]);

  return <>{(mount || innerMount) && children(innerMount && mount)}</>;
};

export const MobilePopup = withCloseOnPopState((props) => {
  const { open } = props;
  return (
    <MountPopup mount={open}>
      {(innerMount) => <PopupPageContainer {...props} open={innerMount} />}
    </MountPopup>
  );
});
