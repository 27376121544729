import { gql } from '@apollo/client';

export const connectionTemplate = (nodeFragment) => `
  edges {
    cursor
    node {
      ${nodeFragment}
    }
  }
  pageInfo {
    endCursor
    hasNextPage
  }
`;

export const PageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

export const OffsetPageInfoFragment = gql`
  fragment OffsetPageInfoFragment on OffsetPageInfo {
    hasNextPage
    hasPreviousPage
    totalCount
    totalPages
    last
    first
    page
  }
`;
