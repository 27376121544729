import React from 'react';
import { CircularProgress, Typography as _Typography } from '@material-ui/core';
import styled from 'styled-components';
import STYLED_MACROS from 'styles/styledMacros';

export const Typography = styled(_Typography)`
  ${(props) => props?.['data-ellipsis'] && `
    display: inline-block !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `}
`;

export const LoaderOrTypo = ({ loading, children, ...loaderProps }) => {
  if (loading) {
    return <CircularProgress {...loaderProps} />;
  }
  return children;
};

export const TitleTypo = styled(Typography)`
  font-family: ${STYLED_MACROS.TYPOGRAPHY.TITLE};
  font-size: ${(props) => !props.variant && '1.25rem'};
  opacity: 1;
  ${(props) => props['data-withmargin']
    && `
    margin-bottom: ${props.theme.spacing(2)}px;
  `}
`;

export const MontserratTypo = styled(TitleTypo)`
  font-family: 'Montserrat', 'sans-serif';
`;

export const IndicatorTitleTypo = styled(TitleTypo)`
  opacity: 0.7;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: ${(props) => !props.variant && '0.875rem'};
`;

export const BlurryTitleTypo = styled(TitleTypo)`
  opacity: 0.4;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: ${(props) => !props.variant && '0.875rem'};
`;

export const IndicatorTypo = styled(Typography)`
  opacity: 0.7;
`;

export const BlurryTypo = styled(Typography)`
  opacity: 0.3;
`;

export const ErrorTypo = styled(Typography)`
  color: ${STYLED_MACROS.PALETTE.ERROR.MAIN};
`;

export const ErrorSpan = styled.span`
  color: ${STYLED_MACROS.PALETTE.ERROR.MAIN};
  font-weight: bold;
`;

export const FrequentlyTypo = styled(Typography)`
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color || '#FFFFFF'};
  padding-right: ${({ paddingRight }) => paddingRight || 0}px;
  font-size: ${({ size }) => `${size || 16}px`};
  font-family: ${({ font }) => font || 'NotoSansCJKKR-Regular'}, 'sans-serif';
  opacity: ${({ opacity }) => opacity || 1};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || '-0.8'}px;
  line-height: ${({ lineHeight }) => `${typeof lineHeight !== 'undefined' ? `${lineHeight}px` : 'normal'}`};
  text-decoration: ${({ decoration }) => decoration || 'none'};
  word-break: break-all;
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre-line'};
  opacity: ${({ opacity }) => opacity};
  text-align: ${({ textAlign }) => textAlign};
  text-shadow: ${({ textShadow }) => textShadow};
  text-decoration-line: ${({ textDecorationLine }) => textDecorationLine || 'none'};
`;
