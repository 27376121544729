import { CONSTANTS } from 'helpers/constants';

const STYLED_MACROS = {
  BACKGROUND: {
    DARK: (props) => props.theme.background.dark,
    DARK_HOVER: (props) => props.theme.background.darkHover,
    DARK_ACTIVE: (props) => props.theme.background.darkActive,
    BROWN: (props) => props.theme.background.brown,
    LIGHT: (props) => props.theme.background.light,
    DEFAULT: (props) => props.theme.background.default,
    HOVER_DEFAULT: (props) => props.theme.background.hoverDefault,
    ACTIVE_DEFAULT: (props) => props.theme.background.activeDefault,
    PHASE: (props) => props.theme.background.phase,
  },
  TYPOGRAPHY: {
    TITLE: (props) => props.theme.typography.title,
  },
  BORDER: {
    DEFAULT: (props) => props.theme.border.default,
  },
  PALETTE: {
    PRIMARY: {
      MAIN: (props) => props.theme.palette.primary.main,
      LIGHT: (props) => props.theme.palette.primary.light,
      DARK: (props) => props.theme.palette.primary.dark,
      CONTRAST_TEXT: (props) => props.theme.palette.primary.contrastText,
    },
    SECONDARY: {
      MAIN: (props) => props.theme.palette.secondary.main,
      LIGHT: (props) => props.theme.palette.secondary.light,
      DARK: (props) => props.theme.palette.secondary.dark,
    },
    BORDER: {
      DEFAULT: (props) => props.theme.palette.border.default,
    },
    WARNING: {
      MAIN: ((props) => props.theme.palette.warning.main),
      BACKGROUND: ((props) => props.theme.palette.warning.background),
      BACKGROUND_HOVER: ((props) => props.theme.palette.warning.backgroundHover),
      BACKGROUND_ACTIVE: ((props) => props.theme.palette.warning.backgroundActive),
    },
    INFO: {
      MAIN: ((props) => props.theme.palette.info.main),
    },
    AVAILABLE: {
      MAIN: ((props) => props.theme.palette.available.main),
    },
    ERROR: {
      MAIN: ((props) => props.theme.palette.error.main),
      HOVER: ((props) => props.theme.palette.error.hover),
    },
    DISABLED: {
      MAIN: ((props) => props.theme.palette.disabled.main),
    },
    SUCCESS: {
      MAIN: ((props) => props.theme.palette.success.main),
      HOVER: ((props) => props.theme.palette.success.hover),
    },
    TYPE_SEPERATED: (props, options) => props.theme.palette[props.type || 'success']?.[options?.background ? 'background' : 'main'] || props.theme.palette[props.type || 'success']?.main,
  },
  MEDIA_QUERY: {
    TINY: `@media screen and (max-width: ${CONSTANTS.TINY_DEVICE_WIDTH}px)`,
    PHONE: (props) => `@media screen and (max-width: ${props.theme.breakpoints.values.sm}px)`,
    MOBILE: (props) => `@media screen and (max-width: ${props.theme.breakpoints.values.md}px)`,
    COMPACT: (props) => `@media screen and (max-width: ${props.theme.breakpoints.values.lg}px)`,
    NOT_TINY: `@media screen and (min-width: ${CONSTANTS.TINY_DEVICE_WIDTH + 1}px)`,
    NOT_PHONE: (props) => `@media screen and (min-width: ${props.theme.breakpoints.values.sm + 1}px)`,
    NOT_MOBILE: (props) => `@media screen and (min-width: ${props.theme.breakpoints.values.md + 1}px)`,
    NOT_COMPACT: (props) => `@media screen and (min-width: ${props.theme.breakpoints.values.lg + 1}px)`,
  },
  get CARD_CLICKABLE() {
    return (props) => `
      cursor: pointer;
      &:hover {
        background-color: ${this.BACKGROUND.HOVER_DEFAULT(props)};
      }
      &:active {
        background-color: ${this.BACKGROUND.ACTIVE_DEFAULT(props)};
      }
  `;
  },
  get DARKCARD_CLICKABLE() {
    return (props) => `
    cursor: pointer;
  
    &:hover {
      background-color: ${STYLED_MACROS.BACKGROUND.DARK_HOVER(props)};
    }
  
    &:active {
      background-color: ${STYLED_MACROS.BACKGROUND.DARK_ACTIVE(props)};
    }
    `;
  },
  get WARNINGCARD_CLICKABLE() {
    return (props) => `
    cursor: pointer;
  
    &:hover {
      background-color: ${STYLED_MACROS.PALETTE.WARNING.BACKGROUND_HOVER(props)};
    }
  
    &:active {
      background-color: ${STYLED_MACROS.PALETTE.WARNING.BACKGROUND_ACTIVE(props)};
    }
    `;
  },
  get OPACITY_CLICKABLE() {
    return () => `
    cursor: pointer;
  
    &:hover {
      opacity: 0.85;
    }
  
    &:active {
      opacity: 0.7;
    }
    `;
  },
  get TRANSPARENT_CLICKABLE() {
    return () => `
    cursor: pointer;
    background-color: transparent;
  
    &:hover {
      background-color: rgba(0, 0, 0, 0.15);
    }
  
    &:active {
      background-color: rgba(0, 0, 0, 0.3);
    }
    `;
  },
};

export default STYLED_MACROS;
