import React, { useState } from 'react';

const DesktopViewContext = React.createContext({
  useDesktopView: false,
  setUseDesktopView: () => {},
});

export default DesktopViewContext;

const { Provider } = DesktopViewContext;

const DesktopViewProvider = ({ children }: { children: React.ReactChildren }) => {
  const [state, setState] = useState({
    useDesktopView: null,
    setUseDesktopView: (useDesktopView) => {
      setState((prevState) => ({ ...prevState, useDesktopView }));
    },
  });

  return (
    <Provider value={state}>
      {
        children
      }
    </Provider>
  );
};

export {
  DesktopViewProvider,
};
