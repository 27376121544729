import { CircularProgress } from '@material-ui/core';
import React, { Suspense } from 'react';
import styled from 'styled-components';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ContentWrapper = styled(Content)`
  flex-direction: row;
`;

const LazyContainer = ({
  children,
  loader,
}: {
  children: React.ReactComponentElement,
  loader: any,
}) => (
  <Suspense fallback={loader || <CircularProgress color="primary" />}>{children}</Suspense>
);

const AppLayout = ({
  children,
  header,
  lazy,
  footer,
  loader,
  forceShowLoader,
}: {
  children: React.ReactElement,
  header: React.ReactElement,
  lazy: Boolean,
  footer: React.ReactElement,
  loader: any,
  forceShowLoader: Boolean,
}) => (
  <LayoutContainer id="appLayout">
    {header}
    <ContentWrapper id="appContentHolder">
      {
        lazy && (
          <Content>
            <LazyContainer loader={loader}>
              {forceShowLoader ? loader : children}
            </LazyContainer>
          </Content>
        ) || (
          <Content>{children}</Content>
        )
      }
    </ContentWrapper>
    {footer}
  </LayoutContainer>
);

export default AppLayout;
