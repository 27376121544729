import { gql } from '@apollo/client';
import { connectionTemplate } from '.';
// updateArticle(input: UpdateArticleInput!): Article!
// deleteArticle(input: ID!): Boolean!

const ArticleFragment = gql`
  fragment ArticleFragment on Article {
    id
    subject
    content
    attachments
    type
    tag
    createdAt
  }
`;

export const createArticleMutation = gql`
  mutation createArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      ...ArticleFragment
    }
  }

  ${ArticleFragment}
`;

export const updateArticleMutation = gql`
  mutation updateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      ...ArticleFragment
    }
  }

  ${ArticleFragment}
`;

export const deleteArticleMutation = gql`
  mutation deleteArticle($id: ID!) {
    deleteArticle(id: $id) 
  }
`;

export const uploadArticleAttachmentsMutation = gql`
  mutation uploadArticleAttachments(
    $id: ID!
    $files: [Upload!]!
    $persistFiles: [String!]
  ) {
    uploadArticleAttachments(
      articleId: $id
      files: $files
      persistFiles: $persistFiles
    ) {
      ...ArticleFragment
    }
  }
  ${ArticleFragment}
`;

export const getArticlesQuery = gql`
  query (
    $first: Int
    $after: String
  ) {
    getArticles(first: $first, after: $after) {
    ${connectionTemplate`
      ...ArticleFragment
    `}
    }
  }
  ${ArticleFragment}
`;

export const UserNotificationFragment = gql`
  fragment UserNotificationFragment on UserNotification {
    id
    key
    summary
    description
    type
    status
    isRead
    isPublic
    userId
    data
    createdAt
    url
  }
`;

export const getMyUserNotificationsQuery = gql`
  query (
    $first: Int
    $after: ID
    $accommodationId: ID
  ) {
    getMyUserNotifications(first: $first, after: $after, accommodationId: $accommodationId) {
      ${connectionTemplate`
        ...UserNotificationFragment
      `}
    }
  }
  ${UserNotificationFragment}
`;

export const deleteUserNotificationMutation = gql`
  mutation ($id: ID!) {
    deleteUserNotification(id: $id)
  }
`;

export const markReadUserNotificationMutation = gql`
  mutation ($ids: [ID!]!) {
    markReadUserNotifications(ids: $ids)
  }
`;
