import { gql } from '@apollo/client';
import { connectionTemplate } from '.';

export const getAccommodationMileagesQuery = gql`
  query getAccommodationMileages($accommodationId: ID!, $filter: MileageQueryFilter, $first: Int, $after: String) {
    getAccommodationMileages(first: $first, after: $after, accommodationId: $accommodationId, filter: $filter) @connection(key: "accommodationMileage", filter: ["accommodationId", "filter"]) {
      ${connectionTemplate`
        id
        phone
        amount
        isCancelled
        isUsed
        createdAt
        paymentType
        parentPayment {
          id
          status
          type
          transactionId
          createdAt
        }
        mileageUser {
          id
          name
        }
      `}
    }
  }
`;

export const getAccommodationMileageUsersQuery = gql`
  query getAccommodationMileageUsers($accommodationId: ID!, $filter: MileageUserQueryFilter, $first: Int, $after: ID) @connection(key: "accommodationMileageUser", filter: ["accommodationId", "filter"]) {
    getAccommodationMileageUsers(first: $first, after: $after, accommodationId: $accommodationId, filter: $filter) {
      ${connectionTemplate`
        id
        phone
        name
        totalVisit
        totalMileage
        currentMileage
        lastVisitedAt
        createdAt
      `}
    }
  }
`;

export const getAccommodationMileageSettingsQuery = gql`
  query getAccommodationMileageSettings($accommodationId: ID!) {
    getSingleAccommodation(id: $accommodationId) {
      id
      mileageSettings {
        enableMileage
        useOutstanding
        mileageSources
        mileageExtraPaySources
        mileageTargets
        expireAfter
        minimumUsableAmount
        rentCreditCardRate
        rentCashRate
        lodgeCreditCardRate
        lodgeCashRate
        manualRate
        platformRate
        receiptAddon
        instruction
        dailySalesResetTime
      }
    }
  }
`;

export const setMileageSettingsMutation = gql`
  mutation setMileageSettings($inputs: [MileageSettingInput]!, $accommodationId: ID!) {
    setMileageSettings(inputs: $inputs, accommodationId: $accommodationId) {
      key
      value
    }
  }
`;

export const cancelMileageMutation = gql`
  mutation cancelMileage($id: ID!) {
    cancelMileage(id: $id) {
      id
      isCancelled
      isUsed
    }
  }
`;

export const AddMileageMutation = gql`
  mutation addMileage($input: AddMileageInput!) {
    addMileage(input: $input) {
      id
    }
  }
`;
