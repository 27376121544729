import { gql } from '@apollo/client';

window.gql = gql;

export const ReservationPaymentFragment = gql`
  fragment ReservationPaymentFragment on Payment {
    id
    type
    amount
    outstandingAmount
    totalAmount
    totalOutstandingAmount
  }
`;

export const RoomFragment = gql`
  fragment RoomFragment on Room {
    id
    name
    number
    kioskId
    defaultSleeps
    maxSleeps
    state
    calculatedState
    checkIn
    checkOut
    dayCheckOut
    priority
    saleState
    roomTypeId
    stateUpdatedAt
    thumbnail
    memo {
      id
      type
      content
    }
    amenities {
      name
      icon
    }
    themes {
      id
      name
    }
  }
`;

export const RoomTypeFragment = gql`
fragment RoomTypeFragment on RoomType {
  id
  name
  alias
  thirdPartyCode
  thumbnail
  checkIn
  checkOut
  quantity
  description
  priority
  rooms {
    ...RoomFragment
    roomDevices {
      id
      name
      type
      power
      state
    }
  }
}
${RoomFragment}
`;

window.RoomTypeFragment = RoomTypeFragment;
