import React, { useState } from 'react';

const PaintRoomContext = React.createContext({
  paint: { value: 'available' },
  setPaint: () => {},
});

export default PaintRoomContext;

const { Provider } = PaintRoomContext;

const PaintRoomProvider = ({ children }: { children: React.ReactChildren }) => {
  const [state, setState] = useState({
    paint: null,
    setPaint: (paint) => {
      setState((prevState) => ({ ...prevState, paint }));
    },
  });

  return (
    <Provider value={state}>
      {
        children
      }
    </Provider>
  );
};

export {
  PaintRoomProvider,
};
