export const CONSTANTS = {
  ACCESS_TOKEN: '__VENDIT__ACCESS_TOKEN',
  REFRESH_TOKEN: '__VENDIT__REFRESH_TOKEN',
  ORDER_TEMPLATE: '__VENDIT__ORDER_TEMPLATE',
  TIMES_TEMPLATE: '__VENDIT__TIMES_TEMPLATE',
  MOBILE_DASHBOARD_HEADER_ACTIONS: 'mobile-dashboard-header-actions',
  LOADING_ANIMATION_DURAITON: 300,
  TINY_DEVICE_WIDTH: 374,
  POLL_INTERVAL: {
    DASHBOARD: 1000 * 60 * 5,
    HOMEPAGE: 1000 * 60 * 5,
  },
  PRICE_TIME_TYPES: {
    UNSET: 'UNSET',
    DISABLED: 'DISABLED',
    AVAILABLE: 'AVAILABLE',
    INFINITE: 'INFINITE',
    EXTENDED: 'EXTENDED',
    EARLY: 'EARLY',
  },
  TUTORIAL_IGNORANT: {
    ROOMSTATUS_USING: '__VENDIT__ROOM_STATUS',
  },
  OTA: {
    YANOLJA: 'YANOLJA',
    YANOLJAHOTEL: 'YANOLJAHOTEL',
    YEOGIEOTTAE: 'YEOGIEOTTAE',
    NAVER: 'NAVER',
    COUPANG: 'COUPANG',
    AIRBNB: 'AIRBNB',
    HOTELGA: 'HOTELGA',
    COOLSTAY: 'COOLSTAY',
    ONEPICK: 'ONEPICK',
    WEMAPE: 'WEMAPE',
    INTERPARK: 'INTERPARK',
    TMON: 'TMON',
    AGODA: 'AGODA',
    EXPEDIA: 'EXPEDIA',
    hyundaiEasyWell: 'hyundaiEasyWell',
    '11ST': '11ST',
    HOTELNOW: 'HOTELNOW',
    V_BOOKING: 'V_BOOKING',
    WEBTOUR: 'WEBTOUR',
    DAILYHOTEL: 'DAILYHOTEL',
    HOTELTIME: 'HOTELTIME',
    PHONE: 'PHONE',
    MANUAL: 'MANUAL',
    ETC: 'ETC',
  },
  REGISTERED_OTA: {
    YANOLJA: 'YANOLJA',
    YANOLJAHOTEL: 'YANOLJAHOTEL',
    YEOGIEOTTAE: 'YEOGIEOTTAE',
    HOTELTIME: 'HOTELTIME',
    TMON: 'TMON',
    INTERPARK: 'INTERPARK',
    NAVER: 'NAVER',
    AIRBNB: 'AIRBNB',
    V_BOOKING: 'V_BOOKING',
    HOTELGA: 'HOTELGA',
    COUPANG: 'COUPANG',
    WEBTOUR: 'WEBTOUR',
    KAKAO: 'KAKAO',
    EXPEDIA: 'EXPEDIA',
    TRIVAGO: 'TRIVAGO',
    HOTELSDOTCOM: 'HOTELSDOTCOM',
    HOTELSCOMBINE: 'HOTELSCOMBINE',
    BOOKINGDOTCOM: 'BOOKINGDOTCOM',
    hyundaiEasyWell: 'hyundaiEasyWell',
    AGODA: 'AGODA',
    ONDA: 'ONDA',
    DAILYHOTEL: 'DAILYHOTEL',
    SALETONIGHT: 'SALETONIGHT',
    HOTELNOW: 'HOTELNOW',
    COOLSTAY: 'COOLSTAY',
  },
  SETTINGS: {
    HIDE_TOMMORROW: '__VENDIT__SETTING_HIDE_TOMMORROW',
    ROOM_AREA_ZOOM: '__VENDIT__SETTING_ROOM_AREA_ZOOM',
    RESERVATION_DIRECTION: '__VENDIT__SETTING_RESERVATION_DIR',
    STATISTICS_VIEW_TYPE: '__VENDIT__STAT_VIEW_TYPE',
    ROOM_VIEW_TYPE: '__VENDIT__SETTING_ROOM_VIEW_TYPE',
    ROOM_SORT_DIR: '__VENDIT__SETTING_ROOM_SORT_DIR',
    COLORBLIND_MODE: '__VENDIT__COLORBLIND_MODE',
    VISIBILITY_MODE: '__VENDIT__VISIBILITY_MODE',
    TIME_INDICATOR: '__VENDIT__SETTING_TIME_INDICATOR',
    ROOMS_PER_ROW: '__VENDIT__ROOMS_PER_ROW',
    SHOW_NFS: '__VENDIT__SHOW_NFS',
    SHOW_TEMPORAL: '__VENDIT__SHOW_TEMPORAL',
    GROUP_BY_WING: '__VENDIT__GROUP_BY_WING',
    WING_DIR: '__VENDIT__WING_DIR',
    MUTE_MASTER_SOUND: '__VENDIT__SETTING_MUTE_MASTER_SOUND',
    SHOW_TEMPERATURE: '__VENDIT__SHOW_TEMPERATURE',
    SHOW_RCU_STATUS: '__VENDIT__SHOW_RCU_STATUS',
    SEPERATE_UPCOMINGS: '__VENDIT__SEPERATE_UPCOMINGS',
    PRIMARY_MEMOTYPE: '__VENDIT__PRIMARY_MEMOTYPE',
  },
  THIRDPARTY_PROVIDER: {
    SAMSUNG_SMART_ACCESS: 'SamsungSmartAccess',
    SANHA_WINGS: 'SanhaWings',
  },
  ATOM_KEYS: {
    PERMANENT_STATE: 'PERMANENT_STATE_ATOM_KEY',
  },
  NOTICE_TYPES: {
    EVENT: 1,
    NOTICE: 2,
  },
};

export const DAY_NUMBER_MAP = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
};

export const CCU_TEMPLATES = {
  TEMP_ENABLED: ['TheMR', 'Seereal', 'Icrew', 'Jada369'],
};

export const AUDIO_CLOUDFRONT_HOST = 'https://d1fyke3teiod60.cloudfront.net';

export const IMAGE_CLOUDFRONT_HOST = 'https://d2xlbl9i4adme7.cloudfront.net';

export const RECORD_PER_PAGE = 20;

export const DATEFORMAT_DATETIME_A = 'YYYY년 MM월 DD일 HH:mm';
export const DATEFORMAT_DATEONLY = 'YYYY년 MM월 DD일';

export const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_STAGING_BUILD === 'true';
export const CONCIREGE_HOST =
  process.env.REACT_APP_CONCIERGE_HOST ||
  (!isDevelopment && 'https://concierge.vendit.co.kr') ||
  'http://vcloud-concierge-dev-frontend.s3-website.ap-northeast-2.amazonaws.com';
// 'http://192.168.35.97:3000';

export const API_HOST =
  process.env.REACT_APP_API_HOST ||
  (isDevelopment && 'https://dev.vendit.co.kr') ||
  'https://api.vendit.co.kr';
export const WS_API_HOST =
  process.env.REACT_APP_WS_API_HOST ||
  (isDevelopment && 'wss://subdev.vendit.co.kr') ||
  'wss://subscription.vendit.co.kr';

export const PASSWORD_VALIDATOR =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/; // 8글자 이상, 특문 / 문자 / 숫자 각 1개 이상씩 포함

export const DEFAULT_TRANSITION = {
  transition: 'all 0.4s ease-in-out',
};

export const PAYMENT_TYPES = ['creditCard', 'cash', 'mileage', 'platform'];
export const PAYMENT_CRETEDBYS = ['manual', 'auto', 'kiosk', 'bookingEngine'];
export const PAYMENT_STATUSES = ['pending', 'paid', 'cancelled'];

export const COLORS = {
  RED: '#e62929',
  PINK_RED: '#e95354',
  ORANGE: '#e68929',
  WHITE: '#FFFFFF',
  YELLOW: '#e8e34e',
  BASIC_BLUETONE_BACKGROUND:
    'transparent linear-gradient(180deg, #E7ECFFD1 0%, #D7E0FB75 100%) 0% 0% no-repeat padding-box;',
  INACTIVE_MENU_TEXT: '#B2B2B2',
  DEEP_GRAY: '#646464',
  BROWN: '#BEA46B',
  DEEP_BLACK: '#1a1a1a',
  LIGHT_BLACK: '#323232',
  LIGHT_GRAY: '#e7e7e7',
  LIGHT_GRAY_2: '#ebebeb',
  BLACK_2A: '#22222a',
  GRAY_D2: '#d2d2d2',
  UNIT: '#999999',
  CART_DROPBOX_BLACK_COLOR: '#2B2C35',
  DARK: '#31323D',
  CONTROL_LIGHT_DARK: '#505050',
  BORDER_GRAY: '#d4d4d4',
  WHITE_GRAY: '#f5f5f5',
  CIRCLE_IN_ACTIVE: '#f7f7f7',
  TYPO_IN_ACTIVE: '#d4d4d4',
  GRAY_01: '#4f4f4f',
  ACTIVE_BLACK: '#191a24',
};

export const VOICE_ALARM_TEMPLATES = [
  'expireSoon',
  'expiredNotification',
  'expireIn30Min',
  'expireIn25Min',
  'expireIn20Min',
  'expireIn15Min',
  'expireIn10Min',
  'expireIn5Min',
  'carReady',
  'openDoor',
  'robAlramTriggered',
  'companyWaitInFront',
  'carDisturbing',
  'serviceReady',
  'serviceArrivalSoon',
  'visitSoon',
  'breakfastReady',
  'complainsAreRaining',
  'pleaseBeMoreQuite',
  'volumeDownTelevision',
  'doNotThrowThroughWindow',
  'evacuationAlarm',
  'fireAlarm',
  'powerDownOnExpire',
  'powerDownSoon',
  'excessFeeNotification',
];

export const JADA_VOICE_ALRAM_MAP = {
  expireSoon: 15,
  expiredNotification: 16,
  expireIn30Min: 41,
  expireIn25Min: 40,
  expireIn20Min: 39,
  expireIn15Min: 38,
  expireIn10Min: 37,
  expireIn5Min: 36,
  carReady: 35,
  openDoor: 34,
  robAlramTriggered: 32,
  companyWaitInFront: 31,
  carDisturbing: 30,
  serviceReady: 26,
  serviceArrivalSoon: 28,
  visitSoon: 37,
  breakfastReady: 25,
  complainsAreRaining: 24,
  pleaseBeMoreQuite: 23,
  volumeDownTelevision: 22,
  doNotThrowThroughWindow: 21,
  evacuationAlarm: 20,
  fireAlarm: 19,
  powerDownOnExpire: 18,
  powerDownSoon: 17,
  excessFeeNotification: 14,
};

export const AMENITY_LIST = [
  {
    name: '조식',
    icon: 'breakfast',
  },
  {
    name: '간식제공',
    icon: 'snack',
  },
  {
    name: '셀프바 이용',
    icon: 'selfbar',
  },
  {
    name: '무료발렛',
    icon: 'freevalet',
  },
  {
    name: '발렛',
    icon: 'valet',
  },
  {
    name: '모닝콜',
    icon: 'morningCall',
  },
  {
    name: '무료 커피',
    icon: 'freeCoffee',
  },
  {
    name: '욕조',
    icon: 'bathtub',
  },
  {
    name: '루프탑뷰',
    icon: 'rooftopView',
  },
  {
    name: '루프탑',
    icon: 'rooftop',
  },
  {
    name: '코너뷰',
    icon: 'cornerView',
  },
  {
    name: '테라스',
    icon: 'terrace',
  },
  {
    name: '와이파이',
    icon: 'wifi',
  },
  {
    name: '마운틴뷰',
    icon: 'mountainView',
  },
  {
    name: '시티뷰',
    icon: 'cityView',
  },
  {
    name: '호수뷰',
    icon: 'lakeView',
  },
  {
    name: '오션뷰',
    icon: 'oceanView',
  },
  {
    name: '스타일러',
    icon: 'styler',
  },
  {
    name: '키친',
    icon: 'kitchen',
  },
  {
    name: '침대',
    icon: 'bed',
  },
  {
    name: '싱글침대',
    icon: 'bed',
  },
  {
    name: '더블침대',
    icon: 'bed',
  },
  {
    name: '침대2개',
    icon: 'multiBed',
  },
  {
    name: '침대3개',
    icon: 'multiBed',
  },
  {
    name: '2층침대',
    icon: 'doubleDeck',
  },
  {
    name: '킹사이즈 침대',
    icon: 'kingBed',
  },
  {
    name: '퀸사이즈 침대',
    icon: 'queenBed',
  },
  {
    name: '킹사이즈 침대 2개',
    icon: 'multiKingBed',
  },
  {
    name: '퀸사이즈 침대 2개',
    icon: 'multiQueenBed',
  },
  {
    name: '모바일 충전기',
    icon: 'mobileCharger',
  },
  {
    name: '간이주방',
    icon: 'portableKitchen',
  },
  {
    name: '조리가능',
    icon: 'cookable',
  },
  {
    name: '보조침대',
    icon: 'subBed',
  },
  {
    name: '전자레인지',
    icon: 'microwave',
  },
  {
    name: '커피포트',
    icon: 'coffeePot',
  },
  {
    name: '고데기',
    icon: 'straightener',
  },
  {
    name: '안마의자',
    icon: 'massageChair',
  },
  {
    name: 'PC',
    icon: 'pc',
  },
  {
    name: '커플PC',
    icon: 'couplePC',
  },
  {
    name: '3PC',
    icon: 'couplePC',
  },
  {
    name: '4PC',
    icon: 'couplePC',
  },
  {
    name: '고성능PC',
    icon: 'highendPC',
  },
  {
    name: '고성능 커플PC',
    icon: 'highendCouplePC',
  },
  {
    name: '넷플릭스',
    icon: 'netflix',
  },
  {
    name: '대형 TV',
    icon: 'bigsizeTV',
  },
  {
    name: '노래방',
    icon: 'karaoke',
  },
  {
    name: 'WAVE',
    icon: 'wave',
  },
  {
    name: 'WATCHA',
    icon: 'watcha',
  },
  {
    name: '웰컴기프트',
    icon: 'welcomeGift',
  },
  {
    name: '어메니티',
    icon: 'amenity',
  },
  {
    name: '온돌',
    icon: 'ondol',
  },
  {
    name: '스파',
    icon: 'spa',
  },
  {
    name: '고급침구',
    icon: 'highendBedding',
  },
  {
    name: '전기장판',
    icon: 'eletricPad',
  },
  {
    name: '난방',
    icon: 'heater',
  },
  {
    name: '에어컨',
    icon: 'aircon',
  },
  {
    name: '공기청정기',
    icon: 'airPurifier',
  },
  {
    name: '냉장고',
    icon: 'fridge',
  },
  {
    name: '오락기',
    icon: 'arcadeGame',
  },
  {
    name: '당구대',
    icon: 'billard',
  },
  {
    name: '리클라이너',
    icon: 'recliner',
  },
  {
    name: '소파',
    icon: 'sofa',
  },
  {
    name: '세탁기',
    icon: 'washer',
  },
  {
    name: '밥솥',
    icon: 'riceCooker',
  },
  {
    name: '인덕션',
    icon: 'induction',
  },
  {
    name: '드라이기',
    icon: 'drier',
  },
  {
    name: '더블뷰',
    icon: 'doubleView',
  },
  {
    name: '사이드뷰',
    icon: 'sideView',
  },
  {
    name: '제트스파',
    icon: 'zetSpa',
  },
  {
    name: '문서복사',
    icon: 'document',
  },
  {
    name: '슈퍼싱글침대',
    icon: 'superSingleBed',
  },
  {
    name: '디즈니플러스',
    icon: 'bigsizeTV',
  },
  {
    name: '빔프로젝터',
    icon: 'bigsizeTV',
  },
  {
    name: '150인치 빔',
    icon: 'bigsizeTV',
  },
  {
    name: 'TV',
    icon: 'bigsizeTV',
  },
  {
    name: '더블침대 2개',
    icon: 'bed',
  },
  {
    name: '옷걸이',
    icon: 'styler',
  },
  {
    name: '65인치 TV',
    icon: 'bigsizeTV',
  },
  {
    name: '75인치 TV',
    icon: 'bigsizeTV',
  },
  {
    name: '커플 소파',
    icon: 'sofa',
  },
  {
    name: '월풀',
    icon: 'spa',
  },
  {
    name: '2인 월풀',
    icon: 'spa',
  },
  {
    name: '2인 스파',
    icon: 'spa',
  },
  {
    name: '게이밍 의자',
    icon: 'gamingChair',
  },
];

export const SERVICE_TABS = {
  SERVICE: [
    { rule: 'ROOM_SERVICE_ORDER', path: 'room/service' },
    {
      rule: 'ROOM_SERVICE_REQUEST',
      path: 'service/request',
    },
    {
      rule: 'ROOM_SERVICE_GIFT_SHOP',
      path: 'service/giftshop',
    },
    {
      rule: 'ROOM_SERVICE_RESTAURANT',
      path: 'service/restaurant',
    },
    {
      rule: 'ROOM_SERVICE_CONVENIENT',
      path: 'service/convenient',
    },
    {
      rule: 'ROOM_SERVICE_HOTEL_GUIDE',
      path: 'service/hotelguide',
    },
  ],
  RESERVATION: [{ rule: '', path: '' }],
  CONTROL: [{ rule: '', path: '' }],
};

export const MENU_TABS = {
  'service/myroom': [
    {
      rule: 'MENU_ORDER_CRAT_001',
      type: 'mypage',
    },
  ],
  service: [
    {
      rule: 'MENU_ORDER_SEARCH_001',
      type: 'search',
    },
    {
      rule: 'MENU_ORDER_CRAT_001',
      type: 'mypage',
    },
  ],
  amenity: [
    {
      rule: 'MENU_ORDER_SEARCH_001',
      type: 'search',
    },
    {
      rule: 'MENU_ORDER_CRAT_001',
      type: 'mypage',
    },
  ],
  order: [
    {
      rule: 'MENU_ORDER_SEARCH_001',
      type: 'search',
    },
    {
      rule: 'MENU_ORDER_CRAT_001',
      type: 'mypage',
    },
  ],
  request: [
    {
      rule: 'MENU_REQ_SEARCH_001',
      type: 'search',
    },
  ],
  gift: [
    {
      rule: 'MENU_GIFT_SEARCH_001',
      type: 'search',
    },
  ],
  restaurant: [
    {
      rule: 'MENU_RESTR_SEARCH_001',
      type: 'search',
    },
  ],
  convenient: [
    {
      rule: 'MENU_CONV_SEARCH_001',
      type: 'search',
    },
  ],
  hotelguide: [
    {
      rule: 'MENU_HTGD_SEARCH_001',
      type: 'search',
    },
  ],
  cart: [],
  search: [
    {
      rule: 'MENU_HTGD_SEARCH_001',
      type: 'mypage',
    },
  ],
  myroom: [
    {
      rule: 'MENU_ROOM_CRAT_001',
      type: 'mypage',
    },
  ],
  'order/detail': [
    {
      rule: 'MENU_ORDER_SEARCH_001',
      type: 'search',
    },
    {
      rule: 'MENU_ROOM_CRAT_001',
      type: 'mypage',
    },
  ],
  'order/payment': [
    {
      rule: 'MENU_ORDER_SEARCH_001',
      type: 'search',
    },
    {
      rule: 'MENU_ROOM_CRAT_001',
      type: 'mypage',
    },
  ],
  'giftshop/detail': [
    {
      rule: 'MENU_ROOM_CRAT_001',
      type: 'mypage',
    },
  ],
  'room/service': [
    {
      rule: 'MENU_ORDER_SEARCH_001',
      type: 'search',
    },
    {
      rule: 'MENU_ORDER_CRAT_001',
      type: 'mypage',
    },
  ],
  'service/detail': [
    {
      rule: 'MENU_ORDER_CRAT_001',
      type: 'mypage',
    },
  ],
};

export const INVISIBLE_SERVICE_NAVIGATION_BAR_LIST = [
  'auth',
  'mypage',
  'service/detail',
  'service/search',
  'service/payment/complete',
  'service/payment',
  'giftshop/detail',
  'amenity/request/complete',
  'restaurant/detail',
  'restaurant/reservation',
  'restaurant/reservation/complete',
  'restaurant/reservation/cancel',
  'restaurant/payment',
  'amenity/order/payment',
  'giftshop/order/payment',
  'room/service/plural/payment',
  'room/service/plural/payment/complete',
  'room/service/plural/payment/cancel/complete',
  'service/plural/payment/complete',
  'order/cancelComplete',
  'CONCIERGE_ORDER/payment/result',
  'LATE_CHECKOUT/payment/result',
  'service/collectInfo',
];

export const CONCIERGE_TYPE = {
  SERVICE_CATEGORY: {
    ROOM_SERVICE: 1,
    AMENITY: 2,
    GIFT_SHOP: 3,
    RESTAURANT: 4,
    FACILITY: 5,
    HOTEL_GUIDE: 6,
  },
  FACILITY: {
    DATE_TYPE: {
      DATE: 1,
      ONLY_TIME: 2,
    },
  },
};

export const CLOUD_FRONT_URL = 'https://d38ycnm2m54gz2.cloudfront.net';
