import { gql } from '@apollo/client';
import { connectionTemplate } from '.';

export const generateCaptcha = gql`
  mutation {
    generateCaptcha {
      svg
      hash
    }
  }
`;

export const signInMutation = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      token {
        accessToken
        refreshToken
      }
    }
  }
`;

export const signInByAdminMutation = gql`
  mutation signInByAdmin($identity: String!) {
    signInByAdmin(identity: $identity) {
      token {
        accessToken
        refreshToken
      }
    }
  }
`;

export const verifyDuplicateQuery = gql`
  query($input: VerifyDuplicateInput!) {
    verifyDuplicate(input: $input) {
      verified
      error
    }
  }
`;

export const signUpMutation = gql`
  mutation($input: SignUpInput!) {
    signUp(input: $input) {
      user {
        id
      }
    }
  }
`;

export const anonymouseSignInMutaton = gql`
  mutation anonymousSignIn($token: String!) {
    anonymousSignIn(token: $token) {
      success
      error
    }
  }
`;

export const getUsersQuery = gql`
  query getUsers($filter: UsersFilter) {
    getUsers(filter: $filter) {
      ${connectionTemplate`
        id
        identity
        name
        nickname
      `}
    }
  }
`;

export const getUserLogsQuery = gql`
  query (
    $accommodationId: ID!,
    $relatedId: ID!
    $first: Int
    $after: String
  ) {
    getUserLogs(accommodationId: $accommodationId, relatedId: $relatedId, first: $first, after: $after) {
    ${connectionTemplate`
      id
      type
      log
      data
      createdAt
      user {
        id
        name
        nickname
      }
    `}
    }
  }
`;

export const myContactsQuery = gql`
  query myContacts ($type: String) {
    myContacts(type: $type) {
      id
      type
      contact
    }
  }
`;

export const addUserContactMutation = gql`
  mutation addUserContact($input: UserContactInput!) {
    addUserContact(input: $input) {
      id
      type
      contact
    }
  }
`;

export const deleteUserContactMutation = gql`
  mutation deleteUserContact($id: ID!) {
    deleteUserContact(id: $id) 
  }
`;

export const EmployeeFragment = gql`
  fragment EmployeeFragment on Employee {
    id
    authority
    identity
    phone
    email
    name
    nickname
    createdAt
  }
`;

export const addUserToAccommodationMutation = gql`
  mutation ($userId: ID!, $accommodationId: ID!, $authority: String!) {
    addUserToAccommodation(userId: $userId, accommodationId: $accommodationId, authority: $authority) {
      id
      name
      employees {
        ...EmployeeFragment
      }
    }
  }

  ${EmployeeFragment}
`;

export const deleteUserFromAccommodationMutation = gql`
  mutation ($userId: ID!, $accommodationId: ID!) {
    deleteUserFromAccommodation(userId: $userId, accommodationId: $accommodationId) {
      id
      name
      employees {
        ...EmployeeFragment
      }
    }
  }
  ${EmployeeFragment}
`;
