import { createTheme } from '@material-ui/core';
import { CONSTANTS } from 'helpers/constants';

const PALETTE = {
  BACKGROUNDS: {
    DARK: '#373d53',
    DARKER: '#272930',
    DARKERHOVER: '#292E41',
    DEFAULT: '#ffffff',
    HOVER_DEFAULT: '#43475B',
    ACTIVE_DEFAULT: '#2F3240',
    DARK_HOVER: '#2b2f3e',
    DARK_ACTIVE: '#23252f',
    DARK_HOVER_LIGHTER: '#3c4256',
    DARK_ACTIVE_LIGHTER: '#43495f',
    BROWN: '#C59764',
    PHASE: '#999999',
  },
  PRIMARY: {
    MAIN: '#eee',
    CONTRAST: '#000',
  },
  SECONDARY: {
    MAIN: '#7681FF',
  },
  DISABLED: '#878787',
};

const muiDefaultTheme = createTheme();

const useColorBlindMode = localStorage.getItem(CONSTANTS.SETTINGS.COLORBLIND_MODE);

export const originTheme = {
  typography: {
    subtitle1: {
      fontSize: '1.125rem',
      lineHeight: 1.3,
    },
    subtitle2: {
      fontSize: '1rem',
      lineHeight: 1.2,
    },
    allVariants: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'pre',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1080,
      lg: 1640,
      xl: 1920,
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: PALETTE.PRIMARY.MAIN,
      contrastText: '#000000',
    },
    secondary: {
      main: PALETTE.SECONDARY.MAIN,
    },
    background: {
      paper: '#666b82',
      default: PALETTE.BACKGROUNDS.DEFAULT,
    },
    success: {
      main: useColorBlindMode && '#56B4E8' || '#78BB00',
      hover: useColorBlindMode && '#56B4E8' || '#72ab0d',
    },
    warning: {
      main: useColorBlindMode && '#EFE341' || '#F9A71E',
      background: useColorBlindMode && '#c6b901' || '#df8b00',
      backgroundHover: useColorBlindMode && '#ce8100' || '#ce8100',
      backgroundActive: useColorBlindMode && '#c27900' || '#c27900',
    },
    error: {
      main: useColorBlindMode && '#9D4B0B' || '#FF5260',
      hover: useColorBlindMode && '#9D4B0B' || '#E92D3C',
    },
    available: {
      main: 'white',
      background: 'rgba(255, 255, 255, 0.4)',
    },
    longTerm: {
      main: '#f95b1e',
    },
    info: {
      main: useColorBlindMode && '#0072B1' || '#0090bb',
    },
    disabled: {
      main: PALETTE.DISABLED,
    },
    blurred: {
      main: 'rgba(255, 255, 255, 0.3)',
    },
    indicator: {
      main: 'rgba(255, 255, 255, 0.55)',
    },
    default: {
      main: 'white',
    },
    border: {
      default: 'rgba(255, 255, 255, 0.15)',
    },
  },
  border: {
    default: '1px solid rgba(255, 255, 255, 0.15)',
  },
  background: {
    dark: PALETTE.BACKGROUNDS.DARK,
    darkHover: PALETTE.BACKGROUNDS.DARK_HOVER,
    darkActive: PALETTE.BACKGROUNDS.DARK_ACTIVE,
    default: PALETTE.BACKGROUNDS.DEFAULT,
    light: '#696e81',
    hoverDefault: PALETTE.BACKGROUNDS.HOVER_DEFAULT,
    activeDefault: PALETTE.BACKGROUNDS.ACTIVE_DEFAULT,
    brown: PALETTE.BACKGROUNDS.BROWN,
    phase: PALETTE.BACKGROUNDS.PHASE,
  },
  overrides: {
    MuiFab: {
      root: {
        height: 48,
        width: 48,
        fontSize: '1.1rem',
      },
    },
    MuiDialogContent: {
      root: {
        whiteSpace: 'pre-line',
      },
    },
    MuiChip: {
      root: {
        padding: 0,
        backgroundColor: 'transparent',
      },
      label: {
        paddingLeft: 0,
        fontSize: '1rem',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        height: 40,
        fontSize: '1rem',
      },
      contained: {
        color: 'white',
        backgroundColor: PALETTE.BACKGROUNDS.DARKER,
        '&:hover': {
          backgroundColor: PALETTE.BACKGROUNDS.DARKERHOVER,
        },
      },
    },
    MuiTypography: {
      alignCenter: {
        justifyContent: 'center',
      },
      gutterBottom: {
        marginBottom: 8,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: PALETTE.PRIMARY.MAIN,
        fontSize: '0.825rem',
        fontWeight: '600',
        color: PALETTE.PRIMARY.CONTRAST,
        maxWidth: 'none',
      },
    },
    MuiTabs: {
      root: {
        background: PALETTE.BACKGROUNDS.DARKER,
        height: 40,
        minHeight: 40,
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        background: PALETTE.BACKGROUNDS.DARKER,
        borderRadius: '8px 8px 0px 0px',
        height: 40,
        minHeight: 40,
        fontSize: '1rem',
        '&.Mui-selected': {
          background: PALETTE.BACKGROUNDS.DARK,
        },
        '&:hover:not(.Mui-selected)': {
          background: PALETTE.BACKGROUNDS.DARKERHOVER,
        },
        [muiDefaultTheme.breakpoints.up('lg')]: {
          minWidth: 96,
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 8,
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 8,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '1rem',
        '&$error': {
          fontWeight: 500,
        },
      },
      contained: {
        marginLeft: 8,
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: 4,
        backgroundColor: PALETTE.BACKGROUNDS.DARK,

        '&:hover': {
          backgroundColor: PALETTE.BACKGROUNDS.DARK_HOVER_LIGHTER,
        },
        '&$focused': {
          backgroundColor: PALETTE.BACKGROUNDS.DARK,
        },
        '&$disabled': {
          backgroundColor: 'rgb(72 74 84)',
        },
      },
      inputMarginDense: {
        paddingTop: 15,
        paddingBottom: 15,
      },
      multiline: {
        '&$marginDense': {
          paddingTop: 15,
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: '10px !important',
        paddingBottom: '10px !important',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: PALETTE.BACKGROUNDS.DARKER,
          borderWidth: 2,
        },
        '&$focused $notchedOutline': {
          borderColor: PALETTE.BACKGROUNDS.DARKERHOVER,
        },
        '&:active $notchedOutline': {
          borderColor: PALETTE.BACKGROUNDS.DARKERHOVER,
        },
        '&$disabled': {
          '&:hover $notchedOutline': {
            borderColor: PALETTE.DISABLED,
            borderWidth: 1,
          },
          '&$focused $notchedOutline': {
            borderColor: PALETTE.DISABLED,
            borderWidth: 1,
          },
          '&:active $notchedOutline': {
            borderColor: PALETTE.DISABLED,
            borderWidth: 1,
          },
        },
      },
      notchedOutline: {
        borderColor: PALETTE.BACKGROUNDS.DARK,
      },
      marginDense: {
        height: 48,
      },
      input: {
      },
      colorSecondary: {
        '& $notchedOutline': {
          borderColor: 'rgba(255, 255, 255, 0.7)',
        },
        '&:hover $notchedOutline': {
          borderColor: 'rgba(255, 255, 255, 1)',
        },
        '&$focused $notchedOutline': {
          borderColor: 'rgba(255, 255, 255, 1)',
          borderWidth: 2,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        '&$marginDense': {
          transform: 'translate(14px, 15px) scale(1)',
        },
      },
      filled: {
        '&$marginDense': {
          '&$shrink': {
            opacity: 0,
          },
          transitionProperty: 'all',
        },
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        borderRadius: 8,
      },
    },
  },
};

export const muiWithoutOverrides = createTheme({
  ...originTheme,
  overrides: undefined,
});

createTheme({
  typography: {
    fontSize: 24,
    htmlFontSize: 24,
    allVariants: {
      whiteSpace: 'pre',
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        background: originTheme.background.dark,
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        '&.selected': {
          background: PALETTE.BACKGROUNDS.DARKER,
        },
      },
    },
    MuiInputLabel: {
      marginDense: {
        transform: 'translate(14px, 15px) scale(1)',
      },
    },
  },
});

export const languageFontFamilies = {
  'ko-KR': {
    fontFamily: "'Noto Sans KR', sans-serif",
    fontWeightRegular: 400,
  },
  en: {
    fontFamily: "'Noto Sans KR', sans-serif",
  },
  jp: {
    fontFamily: "'Noto Sans KR', sans-serif",
    title: muiDefaultTheme.typography.fontFamily,
  },
  default: {
    fontFamily: muiDefaultTheme.typography.fontFamily,
    title: muiDefaultTheme.typography.fontFamily,
  },
};

export const MUITheme = createTheme(originTheme);
